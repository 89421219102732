import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import imageResources from '../../assets/image';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { formatAddress } from '../../utils/formatAddress';
import Web3 from 'web3';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ModalOverlay } from '../../components/Modal/ExitGameConfirm';
import { toast } from 'react-toastify';
import axiosInstance from '../../api/axiosConfig';
import { setAuth, setWalletState } from '../../store/gameSlice';
import { GameWebSocket } from '../../types/game';
import LinkConfirm from '../../components/LinkConfirm';
/* eslint-disable react/no-unescaped-entities */
const isDevelopment = false //process.env.NODE_ENV === 'development';
const GANACHE_RPC_URL = "http://127.0.0.1:8545";
const Home: React.FC<{ ws: WebSocket, setWs: React.Dispatch<React.SetStateAction<GameWebSocket | null>> }> = ({ ws, setWs }) => {

  const navigate = useNavigate();
  const [agreeTerms, setAgreeTerms] = React.useState(() => false);
  const wallet = useSelector((state: RootState) => state.game.wallet);
  const userInfo = useSelector((state: RootState) => state.game.auth.userInfo);
  const [buttonLoading, setButtonLoading] = React.useState(() => false);
  const location = useLocation();
  const [isInGame, setIsInGame] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    checkLocation();
    const reloadLogin = async () => {
      try {


        const hasSign = localStorage.getItem('signature');
        const hasUserInfo = localStorage.getItem('userInfo');
        console.log('hasSign', hasSign);
        console.log('hasUserInfo', hasUserInfo);
        if (hasSign && hasUserInfo) {
          const userInfo = JSON.parse(hasUserInfo);
          const login = await axiosInstance.post('/players/login', {
            wallet_address: userInfo?.wallet_address,
            username: userInfo?.username,
            signature: hasSign
          })

          // lưu token vào localStorage
          if (login.status === 200) {
            localStorage.setItem('token', login.data?.access_token);
            localStorage.setItem('userInfo', JSON.stringify(login.data?.player));
            localStorage.setItem('signature', hasSign);
            dispatch(setAuth({
              userInfo: login.data?.player,
              accessToken: login.data?.access_token,
            }));
          } else {
            throw new Error('Failed to login to server');
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    }

    reloadLogin();


  }, []);

  const checkLocation = async () => {
    try {
      const response = await axiosInstance.get('/players/location');
      if (response.status === 200) {
        const data = response.data;
        if (data.country === 'AU') {
          navigate('/error');
        }
      }
    } catch (error) {
      console.error('Error checking user location:', error);
    }
  };
  const handleAgreeTerms = useCallback(() => {
    setAgreeTerms((prev) => !prev);
  }, [agreeTerms]);

  const checkBalanceAndDeduct = async () => {
    try {
      if (!window.ethereum) {
        toast.warn('Please install MetaMask!');
        return false;
      }

      const web3 = new Web3(
        isDevelopment ? GANACHE_RPC_URL : window.ethereum
      );

      // Nếu đang ở chế độ phát triển, sử dụng tài khoản từ Ganache
      const accounts = await web3.eth.getAccounts();

      const selectedAddress = isDevelopment ? accounts[0] : (wallet?.address || '');

      const balance = await web3.eth.getBalance(selectedAddress);
      const requiredAmount = web3.utils.toWei('0.001', 'ether');

      if (Number(balance) < Number(requiredAmount)) {
        toast.warn('Insufficient balance! Please top up your wallet');
        return false;
      }

      if (isDevelopment) {
        console.log("Running in development mode with Ganache");
      }

      const transaction: {
        from: string;
        to: string;
        value: string;
        gas: string;
        gasPrice?: string;
      } = {
        from: selectedAddress,
        to: process.env.REACT_APP_GAME_CONTRACT_ADDRESS || accounts[9], // Dùng một tài khoản khác trên Ganache nếu cần
        value: requiredAmount,
        gas: process.env.REACT_APP_GAS_LIMIT || '300000'
      };
      if (isDevelopment) {
        transaction.gasPrice = web3.utils.toWei('1', 'gwei');
      }
      console.log(transaction);
      try {
        // Gửi transaction và đợi receipt
        const txHash = await web3.eth.sendTransaction(transaction as any);
        console.log('Transaction hash:', txHash.transactionHash);

        // Đợi transaction được confirm
        const receipt = await web3.eth.getTransactionReceipt(txHash.transactionHash);
        console.log('Transaction receipt:', receipt);

        // Kiểm tra status của transaction
        if (receipt && receipt.status) {
          return true;
        } else {
          toast.warn('Transaction failed or reverted!');
          return false;
        }
      } catch (error) {
        console.error('Transaction error:', error);
        toast.warn('Transaction failed! Check console for details');
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      toast.warn('Error checking balance!');
      return false;
    }
  };

  const handleStartGame = async () => {
    setButtonLoading(true);
    if (!wallet.isConnected) {
      toast.warn('Please connect your wallet first!');
      setButtonLoading(false);
      return;
    }

    if (!agreeTerms) {
      toast.warn('Please agree to the terms first!');
      setButtonLoading(false);
      return;
    }
    // check ws connection
    if (ws.readyState !== WebSocket.OPEN) {
      toast.warn('WebSocket is not connected, page will reload in 3 seconds');
      setButtonLoading(false);
      // reload page to reconnect
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return;
    }

    const isWalletInGame = await sendMessageAndWaitForResponse(ws, {
      type: "isWalletInGame",
      walletAddress: wallet.address
    }, 'checkedWalletInGame');

    if (isWalletInGame.locked) {
      setIsInGame(true);
      ws.close();
      setButtonLoading(false);
      return;
    }


    try {
      const savedWallet = localStorage.getItem('wallet');
      if (!savedWallet) {
        toast.warn('Please connect your wallet first!');
        setButtonLoading(false);
        return;
      }

      const walletData = JSON.parse(savedWallet);

      // Kiểm tra thông tin người dùng từ API
      const res = await axiosInstance.get(`/players/profile/${walletData.address}`);

      if (res.status === 200) {
        console.log('success');
      } else {
        localStorage.removeItem('wallet');
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('signature');
        toast.warn('Please connect your wallet first!');
        setButtonLoading(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
        return;
      }
    } catch (error: any) {
      localStorage.removeItem('wallet');
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('signature');
      toast.warn('Failed to check your wallet');
      setButtonLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }



    const paymentSuccess = await checkBalanceAndDeduct();
    if (!paymentSuccess) {
      setButtonLoading(false);
      return;
    }

    // Chỉ khi transaction thành công mới cho vào phòng chờ
    if (ws.readyState === WebSocket.OPEN) {
      setTimeout(() => {
        ws.send(JSON.stringify({
          type: "join_game",
          username: wallet.playerName || formatAddress(wallet.address || ''),
          walletAddress: wallet.address
        }));
        navigate("/waiting-room", { state: { ws: wallet } });
      }, 3000);
    } else {
      console.error('WebSocket is not connected');
      setButtonLoading(false);
    }
  };

  const sendMessageAndWaitForResponse = (ws: WebSocket, message: any, onlisten: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const responseHandler = (event: MessageEvent) => {
        try {
          const response = JSON.parse(event.data);
          if (response.type === onlisten) {
            resolve(response);
            ws.removeEventListener('message', responseHandler);
          }
        } catch (error) {
          reject(error);
          ws.removeEventListener('message', responseHandler);
        }
      };

      // Lắng nghe phản hồi
      ws.addEventListener('message', responseHandler);

      // Gửi thông điệp đến server
      ws.send(JSON.stringify(message));
    });
  };

  const renderStartButton = () => {
    const isDisabled = !wallet.isConnected || !agreeTerms || buttonLoading;
    const buttonImage = imageResources.frameButtonLg;

    return (
      <button
        className={'relative mx-auto self-center block mt-[20px]'}
        disabled={isDisabled}
      >
        <img src={buttonImage} alt="" />
        <div
          onClick={isDisabled ? undefined : handleStartGame}
          className={`absolute transform -translate-x-1/2 -translate-y-1/2 top-[55%] left-1/2 w-full ${isDisabled ? 'opacity-50' : ''}`}>
          <p

            className={`jersey-15-regular text-Text-01 text-[40px] leading-tight ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          >
            <span className={'flex justify-center gap-2 items-center h-5'}>
              Start game
              {
                buttonLoading && <AiOutlineLoading3Quarters className={'animate-spin w-6 h-6'} />
              }
            </span>
          </p>
          <p className={'jersey-15-regular text-Text-01 text-[28px] leading-tight mt-3'}>
            0.001 ETH
          </p>
        </div>
      </button>
    );
  }

  const renderModalIsInGame = useMemo(() => {
    if (!isInGame) return null;
    // lock screen, lock click, lock scroll
    document.body.style.overflow = 'hidden';
    document.body.style.pointerEvents = 'none';

    return (
      <ModalOverlay>
        {/*<div className={'bg-BG-02 mx-4 sm:mx-0 rounded-[32px] px-[32px] pb-[32px] pt-[16px] max-w-[420px] text-Text-01'}>*/}
        {/*  <p>*/}
        {/*    It looks like you are already in the game. Please wait for the current game to finish before joining another game.*/}
        {/*  </p>*/}
        {/*</div>*/}
        <div className="max-w-md border rounded-lg">
          <div className="flex flex-col p-5 rounded-lg shadow bg-white">
            <div className="flex">
              <div>
                <svg
                  className="w-6 h-6 fill-current text-orange-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
              </div>

              <div className="ml-3">
                <h2 className="font-semibold text-gray-800">
                  Warning
                </h2>
                <p className="mt-2 text-sm text-gray-600 leading-relaxed">
                  It looks like you are already in the game. Please wait for the current game to finish before joining another game.
                </p>
              </div>
            </div>

          </div>
        </div>
      </ModalOverlay>
    );
  }, [isInGame]);

  return (
    <Layout>
      <div className={'container mx-auto'}>
        <div className={'content'}>
          <div className={'blackArea'}>
            <div
              className={
                'container mx-5 sm:mx-10 md:mx-auto py-2 border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
              }
            >
              {renderModalIsInGame}
              <div
                className={
                  'flex justify-between pb-2 border-Stroke-01 border-b-[8px] px-[23px]'
                }
              >
                <p
                  className={
                    'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                  }
                >
                  HIGHEST SCORE: {userInfo?.score || 0}
                </p>
                <p
                  className={
                    'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                  }
                >
                  RANK: {userInfo?.rank || 0}
                </p>
              </div>

              <div
                className={
                  'flex justify-center items-center gap-4 sm:gap-10 mt-8 sm:mt-[37px]'
                }
              >
                <img
                  src={imageResources.ruby}
                  alt=""
                  className={'w-10 sm:w-auto'}
                />
                <p
                  className={
                    'jersey-15-regular text-4xl sm:text-[96px] text-[#E6F4E6] leading-[0]'
                  }
                >
                  PLAY NOW
                </p>
                <img
                  src={imageResources.ruby}
                  alt=""
                  className={'w-10 sm:w-auto transform -scale-x-100'}
                />
              </div>
              <p
                className={
                  'text-2xl sm:text-[32px] jersey-15-regular text-Text-03 text-center mt-4 sm:mt-[30px]'
                }
              >
                Entry Fee: 0.001 ETH
              </p>

              <div
                className={
                  'px-[23px] mt-[30px] flex flex-col md:flex-row gap-[30px] justify-center pb-4'
                }
              >
                <div className={'bg-BG-01 p-[16px] rounded-[8px] flex-1'}>
                  <p
                    className={
                      'jersey-15-regular text-Text-01 text-3xl sm:text-[32px]'
                    }
                  >
                    How 2 Play
                  </p>
                  <ul
                    className={
                      'list-decimal text-Text-03 font-bold text-[15px] pl-6 leading-8 mt-4 sm:mt-[20px]'
                    }
                  >
                    <li>Use your D-pad or ADSW to control your snake.</li>
                    <li>Collect ETH tokens to grow in size.</li>
                    <li>Protect your head from other snakes bodies.</li>
                    <li>Eliminate other snakes with your body.</li>
                    <li>Be the last snake alive to win!</li>
                  </ul>
                  <img
                    src={imageResources.topHowToPlay}
                    alt=""
                    className={'mt-[20px] mb-4'}
                  />
                </div>

                <div className={'flex-1 text-center'}>
                  <div
                    className={
                      'bg-BG-01 p-[16px] rounded-[8px] flex gap-[16px] items-start'
                    }
                  >
                    {agreeTerms ? (
                      <img
                        src={imageResources.checked}
                        alt=""
                        onClick={handleAgreeTerms}
                        className={'cursor-pointer'}
                      />
                    ) : (
                      <img
                        src={imageResources.unchecked}
                        alt=""
                        onClick={handleAgreeTerms}
                        className={'cursor-pointer'}
                      />
                    )}
                    <p className={'body-2-semibold text-Text-03 text-left'}>
                      By proceeding, I confirm I am at least 18 years old and understand this is a competitive game involving real cryptocurrency.
                      {' '}
                      I acknowledge the risk of losing my entry fee and accumulated value, accept full responsibility for my actions, and understand that as an emerging team exploring innovative tools, occasional lag or technical issues may occur.
                      {' '}
                      I also acknowledge that the Ethereater team retains 10% of every transaction to maintain and improve the game.
                      {' '}
                      By continuing, I agree to the terms and conditions outlined in the <LinkConfirm to="/terms-of-service" className="legal__link text-Text-03 cursor-pointer hover:underline font-bold">Terms of Service.</LinkConfirm>
                    </p>
                  </div>
                  {renderStartButton()}
                  <a
                    href={'https://cyber.co/bridge'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}
                    className={'inline-block relative mx-auto mt-[20px]'}
                  >
                    <img src={imageResources.frameButtonMDInactive} alt="" />
                    <div
                      className={
                        'absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full'
                      }
                    >
                      <p
                        className={
                          'jersey-15-regular text-Text-01 text-[28px] leading-tight'
                        }
                      >
                        Bridge to L2
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
