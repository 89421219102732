import React, { useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
import imageResources from '../../assets/image';
import TableRow from './Row';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import axiosInstance from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { Profile } from '../../types/profile';
import { ethers } from 'ethers';
import { PaymentHandlerABI } from '../../contracts';
const PAYMENT_HANDLER_CONTRACT_ADDRESS =
  process.env.REACT_APP_GAME_CONTRACT_ADDRESS;

interface RowData {
  result: string;
  rewardValue: string;
  highlight: boolean;
  time: string;
}

const ProfilePage: React.FC = () => {
  const [profileData, setProfileData] = React.useState<{
    isLoaded: boolean;
    isFetching: boolean;
    isError: boolean;
    data: Profile | null;
  }>({
    isLoaded: false,
    isFetching: false,
    isError: false,
    data: null,
  });

  const walletState = JSON.parse(localStorage.getItem('wallet') || '{}');

  // Sử dụng useRef để lưu trữ giá trị walletState mới nhất
  const walletStateRef = useRef(walletState);

  useEffect(() => {
    walletStateRef.current = walletState;
  }, [walletState]);

  useEffect(() => {
    let attempts = 0;
    let isCancelled = false;

    async function checkWalletState() {
      const currentWalletState = walletStateRef.current;
      if (typeof currentWalletState?.address === 'string') {
        await fetchData();
      } else {
        attempts++;
        if (attempts < 3 && !isCancelled) {
          setTimeout(checkWalletState, 2000); // Kiểm tra lại sau 2 giây
        } else {
          setProfileData({
            isLoaded: false,
            isFetching: false,
            isError: true,
            data: null,
          });
        }
      }
    }

    checkWalletState();

    return () => {
      isCancelled = true; // Hủy bỏ nếu component bị unmount
    };
  }, []); // Chỉ chạy một lần khi component mount

  // Định nghĩa hàm fetchData bên ngoài useEffect
  async function fetchData() {
    try {
      const response = await axiosInstance.get(
        `profile/${walletState?.address}`,
      );
      if (response.status === 200) {
        setProfileData({
          isLoaded: true,
          isFetching: false,
          isError: false,
          data: response.data,
        });
      } else {
        setProfileData({
          isLoaded: false,
          isFetching: false,
          isError: true,
          data: null,
        });
      }
    } catch (error: any) {
      setProfileData({
        isLoaded: false,
        isFetching: false,
        isError: true,
        data: null,
      });
    }
  }

  const [claiming, setClaiming] = React.useState<boolean>(false);

  const handleClaim = async () => {
    if (claiming) return;
    setClaiming(true);

    try {
      if (!window.ethereum || !PAYMENT_HANDLER_CONTRACT_ADDRESS) {
        toast.error(
          'Please install MetaMask or check contract address configuration',
        );
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      const accountAddress = await signer.getAddress();

      // Lấy thông tin claim từ server
      const { data } = await axiosInstance.post('profile/claim', {
        wallet_address: accountAddress,
      });

      if (!data.success || !data?.res?.amount) {
        throw new Error(data.message || 'No claimable amount available');
      }

      const { signature, recipient, amount, nonce } = data.res;

      // Tạo contract instance
      const paymentHandlerContract = new ethers.Contract(
        PAYMENT_HANDLER_CONTRACT_ADDRESS,
        PaymentHandlerABI,
        signer,
      );

      // Gọi smart contract để claim reward
      const tx = await paymentHandlerContract.claimReward(
        signature,
        accountAddress,
        amount,
        nonce,
        {
          gasLimit: 300000,
          gasPrice: await provider.getGasPrice(),
        },
      );

      toast.info('Transaction submitted. Waiting for confirmation...', {
        theme: 'dark',
      });

      const receipt = await tx.wait();

      if (receipt.status === 0) {
        throw new Error('Transaction failed');
      }

      toast.success(
        <div>
          <p>
            Claim successfully{' '}
            <span className={'font-bold text-[#A59EFA]'}>
              {ethers.utils.formatEther(amount)} ETH
            </span>
          </p>
          <p className={'truncate overflow-hidden max-w-[200px]'}>
            Check hash:{' '}
            <a
              className={'text-[#A59EFA]'}
              href={`${process.env.REACT_APP_BSCAN_URL}${receipt.transactionHash}`}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {receipt.transactionHash}
            </a>
          </p>
        </div>,
        {
          theme: 'dark',
          progressStyle: {
            backgroundColor: '#A59EFA',
          },
          autoClose: 10000,
        },
      );
    } catch (error: any) {
      console.error('Claim error:', error);
      if (error.code === 'CALL_EXCEPTION') {
        toast.error(
          'Transaction reverted. Please check your balance and try again.',
        );
      } else if (error.code === 4001) {
        toast.error('Transaction rejected by user');
      } else if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('Claim failed');
      }
    } finally {
      setClaiming(false);
      await fetchData();
    }
  };

  if (profileData.isError) {
    return (
      <Layout>
        <div className={'container mx-auto'}>
          <div className={'content'}>
            <div className={'blackArea !pt-[5vh]'}>
              <div
                className={
                  'container overflow-hidden mx-5 sm:mx-10 md:mx-auto pt-2 border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
                }
              >
                <div
                  className={
                    'flex justify-between pb-2 border-Stroke-01 border-b-[8px] px-[23px]'
                  }
                >
                  <p
                    className={
                      'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                    }
                  >
                    PROFILE
                  </p>
                </div>
                <div className={'bg-BG-01'}>
                  <div
                    className={'flex items-center justify-center min-h-[600px]'}
                  >
                    <p className={'text-Text-01 body-2-semibold'}>
                      {walletStateRef.current?.address
                        ? 'Failed to fetch data'
                        : 'Please connect your wallet'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={'container mx-auto'}>
        <div className={'content'}>
          <div className={'blackArea !pt-[5vh]'}>
            <div
              className={
                'container overflow-hidden mx-5 sm:mx-10 md:mx-auto pt-2 border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
              }
            >
              <div
                className={
                  'flex justify-between pb-2 border-Stroke-01 border-b-[8px] px-[23px]'
                }
              >
                <p
                  className={
                    'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                  }
                >
                  PROFILE
                </p>
              </div>

              <div className={'bg-BG-01 min-h-[600px]'}>
                {profileData.isLoaded ? (
                  <>
                    <div
                      className={
                        'flex items-center px-[24px] pt-[32px] gap-[36px]'
                      }
                    >
                      <div className={'flex items-center gap-[20px]'}>
                        <img src={imageResources.user} alt="" />
                        <div>
                          <p
                            className={
                              'jersey-15-regular text-[40px] text-Text-01 leading-tight'
                            }
                          >
                            {profileData.data?.username}
                          </p>
                          <p
                            className={
                              'leading-tight body-2-semibold text-Text-03'
                            }
                          >
                            {profileData.data?.walletAddress}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          'flex flex-wrap items-center rounded-[8px] bg-Stroke-01 flex-1 px-[16px] py-[12px]'
                        }
                      >
                        <div>
                          <p className={'text-Text-01 body-3-regular'}>
                            You can claim now
                          </p>
                          <div className={'flex items-center gap-2'}>
                            <span
                              className={
                                'jersey-15-regular text-[32px] text-[#A59EFA]'
                              }
                            >
                              {parseFloat(
                                Number(
                                  profileData.data?.claimableAmount ?? 0,
                                ).toFixed(5),
                              )}
                            </span>
                            <img
                              width={'max-w-[18px]'}
                              src={imageResources.singleRuby}
                              alt=""
                            />
                          </div>
                        </div>
                        <button
                          className={`relative ml-auto block ${claiming || profileData?.data?.claimableAmount === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                          onClick={handleClaim}
                          disabled={
                            claiming || profileData?.data?.claimableAmount === 0
                          }
                        >
                          <img src={imageResources.frameButtonClaim} alt="" />
                          <div
                            className={
                              'absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full'
                            }
                          >
                            <div
                              className={
                                'jersey-15-regular text-Text-01 text-[24px] leading-tight flex justify-center items-center'
                              }
                            >
                              {claiming ? (
                                <AiOutlineLoading3Quarters
                                  className={'animate-spin w-6 h-6'}
                                />
                              ) : (
                                'CLAIM NOW'
                              )}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>

                    {/* table */}
                    <div className="overflow-x-auto px-4 pb-4 mt-4 bg-BG-01 min-h-[200px] max-h-[480px] overflow-y-auto">
                      {Array.isArray(profileData.data?.transactions) &&
                      profileData.data?.transactions.length > 0 ? (
                        <table className="min-w-full table-auto text-Text-01 rounded-md border-separate border-spacing-y-2">
                          <thead className={'sticky top-[-2px] bg-BG-01'}>
                            <tr className="body-2-semibold text-Text-01 uppercase tracking-wide">
                              <th className="px-4 py-2 text-left w-[165px]">
                                Results
                              </th>
                              <th className="px-4 py-2 text-right w-[140px]">
                                Reward
                              </th>
                              <th className="px-4 py-2 text-right">Time</th>
                            </tr>
                          </thead>
                          <tbody className={'bg-BG-01 space-y-2'}>
                            {/*rows.map((row, index) => (
                          <TableRow
                            key={index}
                            result={row.result}
                            rewardValue={row.rewardValue}
                            time={row.time}
                            highlight={row.highlight}
                          />
                        ))*/
                            profileData.data?.transactions.map(
                              (transaction, index) => (
                                <TableRow
                                  key={index}
                                  result={transaction.results}
                                  rewardValue={transaction.reward}
                                  time={transaction.time}
                                  highlight={
                                    transaction.results === 'TOP 1' &&
                                    !transaction.reward.includes('-')
                                  }
                                />
                              ),
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div
                          className={
                            'flex items-center justify-center min-h-[200px]'
                          }
                        >
                          <p className={'text-Text-01 body-2-semibold'}>
                            No Data
                          </p>
                        </div>
                      )}
                    </div>

                    {/* Notice Section */}
                    <div className="px-6 py-4 bg-BG-01">
                      <div className="border-t-2 border-Stroke-01 pt-4">
                        <h3 className="text-Text-01 jersey-15-regular text-2xl mb-3">
                          Weekly Schedule Notice
                        </h3>
                        <div className="text-Text-03 body-2-regular space-y-4">
                          <p>
                            Every Friday at 5pm EST to 6pm EST the game is
                            closed
                          </p>

                          <div className="bg-[#FF494933] p-4 rounded-lg">
                            <p className="text-[#FF4949] font-bold mb-2">
                              ⚠️ IMPORTANT NOTICE TO ALL PLAYERS ⚠️
                            </p>

                            <div className="text-Text-01 space-y-2">
                              <p>
                                Please note that all winnings must be claimed by
                                5 PM EST each Friday. Failure to claim your
                                winnings by this deadline will result in the
                                loss of your funds, as the smart contract will
                                be emptied at that time.
                              </p>

                              <p>
                                Additionally, the game will be temporarily
                                unavailable for one hour every Friday starting
                                at 5 PM EST for weekly maintenance and contract
                                resets.
                              </p>

                              <p className="font-semibold">
                                Make sure to claim your winnings before the
                                deadline to avoid any inconvenience.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className={'flex items-center justify-center min-h-[600px]'}
                  >
                    <AiOutlineLoading3Quarters
                      className={'animate-spin w-6 h-6'}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProfilePage;
