import React from 'react';
import imageResources from '../../assets/image';
import Layout from '../../components/Layout';
/* eslint-disable react/no-unescaped-entities */


const TermsOfService: React.FC = () => {

  return (
    <Layout>
      <div className={'container mx-auto'}>
        <div className={'content'}>
          <div className={'blackArea !pt-[5vh]'}>
            <div
              className={
                'container overflow-hidden mx-5 sm:mx-10 md:mx-auto pt-2 border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
              }
            >
              <div
                className={
                  'flex justify-between items-center pb-2 border-Stroke-01 border-b-[8px] px-[23px]'
                }
              >
                <p
                  className={
                    'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                  }
                >
                  TERMS OF SERVICE
                </p>
                <p className={'body-2-semibold text-Text-03'}>
                  Last Updated: 10/12/2024
                </p>
              </div>

              <div className={'bg-BG-01'}>
                <div className="pt-[40px] pb-[62px] px-[24px] max-h-[597px] overflow-y-auto body-2-semibold text-Text-03">
                  <img
                    src={imageResources.logo2}
                    className={'block mx-auto mb-4'}
                    alt=""
                  />
                  <p className="font-bold">Welcome to Ethereater!</p>
                  <br />
                  <p>These Terms of Use govern your access to and participation in the Ethereater game and its services. By accessing and playing Ethereater, you agree to comply with these Terms. If you do not agree with these Terms, please do not use our services.</p>
                  <br />
                  <p className="text-Text-01">1. Acceptance of Terms</p>
                  <p>By creating an account or accessing Ethereater, you accept and agree to these Terms of Service. These Terms may be updated periodically, and continued use of the service signifies your acceptance of the modified Terms.</p>
                  <br />
                  <p className="text-Text-01">2. Eligibility</p>
                  <p>You must be at least 18 years old to participate in Ethereater. By accessing or using Ethereater, you confirm that you meet this eligibility requirement. Participation is strictly limited to individuals who meet this age requirement.</p>
                  <br />
                  <p className="text-Text-01">3. Game Mechanics and Blockchain Integration</p>
                  <p>Ethereater operates on the Cyber Layer 2 blockchain and requires ETH deposits for gameplay. Please ensure you have read and understood how the game operates before participating. Players must connect a compatible wallet, such as MetaMask, to interact with the game. Ethereater is an experimental game created by a team exploring the possibilities of blockchain technology. As such, it may include inherent risks or technical limitations.</p>
                  <br />
                  <p className="text-Text-01">4. ETH Deposits, Winnings, and Weekly Claim Deadline</p>
                  <p>Ethereater requires players to deposit ETH to participate in each match. The winner of each match will collect the remaining ETH after eliminations. By participating, you accept the risks associated with ETH deposits and acknowledge that deposits may be lost during gameplay. Ethereater provides no guarantees regarding potential winnings or outcomes.

                    Claim Deadline: Winnings must be claimed by 5 PM EST each Friday, as the smart contract is emptied weekly at this time. Failure to claim winnings by this deadline will result in the forfeiture of any unclaimed funds. The game will be temporarily unavailable for one hour every Friday starting at 5 PM EST for maintenance.

                    The Ethereater team retains 10% of every transaction to maintain and improve the game. By participating, you acknowledge and accept this fee as part of the game mechanics and agree that it supports the ongoing development and operation of Ethereater.   </p>
                  <br />
                  <p className="text-Text-01">5. Risks and Liability Waiver</p>
                  <p>Playing Ethereater involves inherent risks, including but not limited to:</p>
                  <ul className="list-disc pl-6 mt-2 mb-2">
                    <li>Financial loss due to gameplay or smart contract issues.</li>
                    <li>Technical challenges as this is an experimental game built by a team of innovators exploring blockchain technology.</li>
                    <li>Potential technical interruptions or lag.</li>
                  </ul>
                  <p>By playing Ethereater, you acknowledge and accept these risks. Ethereater, its developers, and affiliates shall not be held liable for any losses, damages, or expenses incurred from playing the game or using related services. Participation in Ethereater is entirely at your own risk.</p>
                  <br />
                  <p className="text-Text-01">6. Prohibited Conduct</p>
                  <p>Players are expected to use Ethereater responsibly and ethically. Prohibited actions include, but are not limited to:</p>
                  <ul className="list-disc pl-6 mt-2 mb-2">
                    <li>Exploiting game mechanics.</li>
                    <li>Hacking or attempting to manipulate the system.</li>
                    <li>Using unauthorized tools or software to gain unfair advantages.</li>
                  </ul>
                  <p>Any violation of these rules may result in suspension or termination of access.</p>
                  <br />
                  <p className="text-Text-01">7. Ownership and Intellectual Property</p>
                  <p>All rights, title, and interest in Ethereater, including intellectual property rights, are owned by Ethereater's creators. Any unauthorized use of the game's assets, mechanics, or intellectual property is strictly prohibited.</p>
                  <br />
                  <p className="text-Text-01">8. User Privacy</p>
                  <p>We respect your privacy and will not share your personal information without your consent, except as outlined in our Privacy Policy. By playing Ethereater, you agree to our data collection practices as described in our Privacy Policy.</p>
                  <br />
                  <p className="text-Text-01">9. Modifications to the Service</p>
                  <p>Ethereater may change or discontinue the game or any feature of the game at any time without notice. We are not responsible for any losses that may result from such changes, as this is an experimental project that may evolve over time.</p>
                  <br />
                  <p className="text-Text-01">10. Termination</p>
                  <p>We reserve the right to suspend or terminate your access to Ethereater if you violate these Terms or if required by applicable law.</p>
                  <br />
                  <p className="text-Text-01">11. Governing Law</p>
                  <p>These Terms are governed by the laws of [Your Jurisdiction]. By using Ethereater, you consent to the jurisdiction of these laws.</p>
                  <br />
                  <p className="text-Text-01">12. Acknowledgment of Risks</p>
                  <p>By participating in Ethereater, you understand and accept that this game is experimental and built by a team of innovators exploring blockchain technology. You agree to take full responsibility for your actions and acknowledge that all risks, including potential financial loss, are solely yours.</p>
                  <br />
                  <p className="text-Text-01">13. Contact Information</p>
                  <p>If you have any questions about these Terms, please contact us at support@ethereater.com.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfService;