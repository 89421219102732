import React from 'react';
import styled from 'styled-components';
import { GameWebSocket } from '../../types/game';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setUserAction } from '../../store/gameSlice';
import { useNavigate } from 'react-router';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

interface WinModalProps {
  ws?: GameWebSocket,
  payload?: any
}

const ExitGameConfirm: React.FC<WinModalProps> = ({ ws }) => {
  // useNavigate
  const [loadingButton, setLoadingButton] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <ModalOverlay>
      <div className={'bg-BG-02 mx-4 sm:mx-0 rounded-[32px] px-[32px] pb-[32px] pt-[16px] max-w-[420px]'}>
        <p>
          It looks like you are about to exit the game. Are you sure you want to exit the game?
          <br/>* Your game will be lost.
        </p>
        <div className={'flex items-center justify-end gap-4 mt-4'}>
          <button
            className={'h-10 py-2.5 px-6 rounded-lg text-sm font-medium bg-teal-200 text-teal-800'}
            onClick={() => {
              dispatch(setUserAction({
                confirmExit: false,
              }))
            }}>No
          </button>
          <button
            className={'py-2.5 h-10 px-6 rounded-lg text-sm font-medium text-white bg-teal-600'}
            onClick={() => {
              if (ws?.readyState === WebSocket?.OPEN) {
                ws.send(JSON.stringify({
                  type: 'exit_waiting_room',
                }));
                setLoadingButton(true);
              } else {
                console.error('WebSocket is not connected');
                dispatch(setUserAction({
                  confirmExit: false,
                }))
                
                window.location.href = '/';
              }
            }}>
            {loadingButton ? <AiOutlineLoading3Quarters className={'animate-spin'}  /> : 'Yes'}
          </button>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default ExitGameConfirm;
