import React from 'react';
import './food_style.scss';
import imageResources from '../../assets/image';
import { GameWebSocket } from '../../types/game';
import LinkConfirm from '../LinkConfirm';

const Food: React.FC<{ ws?: GameWebSocket }> = ({ ws }) => {

  const isWs = ws?.readyState === WebSocket.OPEN;

  return (
    <footer className="footer px-[16px] md:px-[32px] mt-auto pt-6 pb-6">
      <div className="footer__content mx-auto flex flex-col md:flex-row items-center justify-between">
        {/* Logo */}
        <div className="w-auto md:w-[15%] lg:w-[20%] footer__logo mb-4 md:mb-0">
          <img
            src={imageResources.logo}
            alt="Ether Eater Logo"
            className="logo w-16 h-auto"
          />
        </div>

        {/* Navigation Links */}
        <nav className="footer__nav hidden md:flex flex-col md:flex-row gap-1 lg:gap-4">
          <LinkConfirm willConfirm={isWs} to="/home" className="nav__link">
            Home
          </LinkConfirm>
          <LinkConfirm willConfirm={isWs} to="/leaderboard" className="nav__link">
            Leaderboard
          </LinkConfirm>
          <LinkConfirm willConfirm={isWs} to="/profile" className="nav__link">
            Profile
          </LinkConfirm>
          <LinkConfirm willConfirm={isWs} to="/how-to-play" className="nav__link">
            How 2 Play
          </LinkConfirm>
        </nav>

        {/* Social Icons and Join Us */}
        <div className="w-auto md:w-[20%] justify-end footer__social flex items-center gap-1 lg:gap-4 mt-8 md:mt-0">
          <a href="https://x.com/playethereater" className="icon icon--close">
            <img src={imageResources.join1} alt="Close Icon" />
          </a>
          <a href="https://t.me/playethereater" className="icon icon--telegram">
            <img src={imageResources.join2} alt="Telegram Icon" />
          </a>
          <span className="join-us">Join Us!</span>
        </div>
      </div>

      {/* Legal Links */}
      <div className="footer__legal mx-auto flex justify-between items-center pt-4 border-t border-Text-03 mt-6">
        <LinkConfirm willConfirm={isWs} to="/privacy-policy" className="legal__link">
          Privacy Policy
        </LinkConfirm>
        <LinkConfirm willConfirm={isWs} to="/terms-of-service" className="legal__link">
          Terms of Service
        </LinkConfirm>
      </div>
    </footer>
  );
};

export default Food;
