import axios, { AxiosInstance } from 'axios';

// Create an Axios instance with default settings
console.log('Current NODE_ENV:', process.env.REACT_APP_NODE_ENV);
console.log('All env variables:', process.env);

const axiosInstance: AxiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? 'http://localhost:3001/api'
      : process.env.REACT_APP_API_URL, // Base URL for all requests
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add interceptor to handle requests before they are sent (request interceptor)
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add headers, such as authentication token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add interceptor to handle responses (response interceptor)
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful response
    return response;
  },
  (error) => {
    // Handle errors, e.g., display error message or redirect to login page
    if (error.response?.status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      // Redirect to login page
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
