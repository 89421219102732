import { useRef, useCallback, useEffect } from 'react';

function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  delay: number
): (...args: Parameters<T>) => void {
  const timeoutRef = useRef<number | undefined>();
  const callbackRef = useRef<T>(callback);
  
  // Update the ref to the latest callback whenever it changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  
  const debouncedFunction = useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      
      timeoutRef.current = window.setTimeout(() => {
        callbackRef.current(...args);
      }, delay);
    },
    [delay] // Only depend on delay
  );
  
  useEffect(() => {
    // Clean up timeout when component unmounts
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  
  return debouncedFunction;
}

export default useDebounce;
