import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import LeaderboardRow from './Row';
import axiosInstance from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface LeaderboardEntry {
  rank: number;
  walletAddress: string;
  name: string;
  gamesWon: number;
  ethEarned: number;
}

const leaderboardData: LeaderboardEntry[] = Array(8)
  .fill({
    rank: 0,
    walletAddress: '0x7f533b5fbf8fe8bc3b7df6cc27fc67744a9a',
    name: '',
    gamesWon: 102932,
    ethEarned: 102,
  })
  .map((entry, index) => ({ ...entry, rank: index + 1 }));

const LeaderBoardPage: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.game.auth);
  const [filter, setFilter] = React.useState<'day' | 'weekly' | 'all-time'>(
    'day',
  );
  const [leaderboardData, setLeaderboardData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLeaderboardData([])
        setLoading(true);
        const response = await axiosInstance.get('/leaderboard', {
          params: {
            type: filter,
          },
        });

        if (
          response.status === 200 &&
          response.data?.leaderboard &&
          response.data?.leaderboard.length > 0
        ) {
          setLeaderboardData(response.data?.leaderboard);
          setError(false);
        } else if(response?.data?.leaderboard.length === 0) {
          setError(false);
          toast.warn('No data');
        }
        else {
          setError(true);
          toast.error('Failed to fetch leaderboard data');
        }
      } catch (error: any) {
        toast.error(error?.message || 'Failed to fetch leaderboard data');
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [filter]);

  return (
    <Layout>
      <div className={'container mx-auto'}>
        <div className={'content'}>
          <div className={'blackArea !pt-[5vh]'}>
            <div
              className={
                'container overflow-hidden mx-5 sm:mx-10 md:mx-auto pt-2 border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
              }
            >
              <div
                className={
                  'flex justify-between items-center pb-2 border-Stroke-01 border-b-[8px] px-[23px]'
                }
              >
                <p
                  className={
                    'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                  }
                >
                  LEADERBOARD
                </p>
                <div className="flex space-x-2 h-[36px] items-center">
                  <button
                    className={`h-full text-Text-01 body-2-semibold py-[2px] px-4 rounded-[35px] ${filter === 'day' ? 'bg-BG-03' : ''}`}
                    onClick={() => setFilter('day')}
                  >
                    DAY
                  </button>
                  <button
                    className={`h-full text-Text-01  body-2-semibold py-[2px] px-4 rounded-[35px] ${filter === 'weekly' ? 'bg-BG-03' : ''}`}
                    onClick={() => setFilter('weekly')}
                  >
                    WEEKLY
                  </button>
                  <button
                    className={`h-full text-Text-01  body-2-semibold py-[2px] px-4 rounded-[35px] ${filter === 'all-time' ? 'bg-BG-03' : ''}`}
                    onClick={() => setFilter('all-time')}
                  >
                    ALL-TIME
                  </button>
                </div>
              </div>

              <div className={'bg-BG-01'}>
                {/* table */}
                <div className="overflow-x-auto px-4 pb-4 bg-BG-01 max-h-[588px] overflow-y-auto">
                  {!loading && !error && leaderboardData.length > 0 ? (
                      <table className="min-w-full table-auto text-Text-01 rounded-md border-separate border-spacing-y-2">
                        <thead className={'sticky top-[-2px] bg-BG-01'}>
                          <tr className="body-2-semibold text-Text-01 uppercase tracking-wide">
                            <th className="px-4 py-2 text-center">RANK</th>
                            <th className="px-4 py-2 text-left">NAME</th>
                            <th className="px-4 py-2 text-left">GAMES WON</th>
                            <th className="px-4 py-2 text-right">ETH EARNED</th>
                          </tr>
                        </thead>
                        <tbody className={'bg-BG-01 space-y-2'}>
                        {leaderboardData.map((entry) => (
                          <LeaderboardRow
                            key={entry.rank}
                            rank={entry.rank}
                            name={entry?.wallet_address}
                            gamesWon={entry?.games_won}
                            ethEarned={entry?.eth_earned}
                            userInfo={userInfo}
                          />
                        ))}
                        </tbody>
                      </table>
                    ) :  leaderboardData.length === 0 ? (
                      <div className={'text-center text-Text-01 min-h-[500px] flex justify-center items-center'}>
                        No data
                      </div>
                    ) :
                    error ? (
                    <div className={'text-center text-Text-01 min-h-[500px] flex justify-center items-center'}>
                      Failed to fetch leaderboard data
                    </div>
                  ) : (
                    <div className={'min-h-[500px] flex justify-center items-center'}>
                      <AiOutlineLoading3Quarters
                        className={'animate-spin w-6 h-6'}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LeaderBoardPage;
