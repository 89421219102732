import React from 'react';
import Navigation from '../Navigation/Navigation_v2';
import styles from './Layout.module.scss';
import Food from '../Food/Food';
import useMediaQuery from '../../hooks/useMediaQuery';
import imageResources from '../../assets/image';
import { GameWebSocket } from '../../types/game';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ExitGameConfirm from '../Modal/ExitGameConfirm';

interface LayoutProps {
  children: React.ReactNode;
  ws?: GameWebSocket;
}

const Layout: React.FC<LayoutProps> = ({ children, ws }) => {
  // if mobile screen, return mobile layout
  const isMobile = useMediaQuery('(max-width: 768px)');
  const userAction = useSelector((state: RootState) => state.game.userAction);
  
  const memoUserAction = React.useMemo(() => {
    if (!userAction.confirmExit) return null;
    return (
      <ExitGameConfirm ws={ws} />
    )
  }, [userAction.confirmExit]);

  if (isMobile)
    return (
      <div className={styles.layout}>
        <div className={styles.main}>
          {memoUserAction}
          <Navigation ws={ws} />
          <main>
            <div className={'container mx-auto'}>
              <div className={'content'}>
                <div className={'blackArea'}>
                  <div
                    className={
                      'container overflow-hidden mx-5 sm:mx-10 md:mx-auto py-[60px] px-[32px] border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
                    }
                  >
                    <img
                      className={'mx-auto'}
                      src={imageResources.isMobile}
                      alt={''}
                    />
                    <p
                      className={
                        'text-center text-Text-03 jersey-15-regular text-[40px] !leading-none mt-[24px]'
                      }
                    >
                      Only available on desktop
                    </p>
                    <p className={'text-center body-2-regular text-Text-01'}>
                      Play games on a laptop for the best experience
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <Food ws={ws} />
      </div>
    );

  return (
    <div className={styles.layout}>
      <div className={styles.main}>
        {memoUserAction}
        <Navigation ws={ws} />
        <main>{children}</main>
      </div>
      <Food ws={ws} />
    </div>
  );
};

export default Layout;
