import React, { useCallback } from 'react';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate , useLocation } from 'react-router-dom';
import { setUserAction } from '../store/gameSlice';

interface OwnProps {
  text?: string;
  className?: string;
  to?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  willConfirm?: boolean;
}

type Props = OwnProps;

const LinkConfirm: React.FC<Props> = (props) => {
  const {text, className, to, onClick, children, willConfirm } = props;
  const userAction = useSelector((state: RootState) => state.game.userAction);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const handleClick = () => {
    if(['/game', '/waiting-room'].includes(location.pathname)) {
      dispatch(setUserAction({
        confirmExit: true,
      }));
    } else {
      navigate(to ?? '/');
    }
  }
  
  return (
    <div className={`${className ?? ''}`} onClick={handleClick}>
      { text ?? ''}
      {children ?? ''}
    </div>
  );
};

export default React.memo(LinkConfirm);
