import React from 'react';
import { IMapColors, mapColors } from '../../utils/mapColors';

interface OwnProps {
  colorId: keyof IMapColors;
}

type Props = OwnProps;

const SnakeHead: React.FC<Props> = (props) => {
  
  const color = mapColors[props.colorId];
  
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.08612 0.12915H13.1421C15.701 0.12915 17.7777 2.20163 17.7777 4.75538V13.2448C17.7777 15.7985 15.701 17.871 13.1421 17.871H1.08612C0.486582 17.871 0 17.3854 0 16.787V1.21308C0 0.614753 0.486582 0.12915 1.08612 0.12915Z"
        fill={color} />
      <path d="M15.7969 4.47778H13.2944V6.97517H15.7969V4.47778Z" fill="#2A2F50" />
      <path d="M15.7969 11.116H13.2944V13.6133H15.7969V11.116Z" fill="#2A2F50" />
    </svg>
  );
};

export default SnakeHead;
