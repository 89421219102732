import React from 'react';
import SnakeHead from '../Snake/SnakeHead';
import { GameState } from '../../types/game';

interface IProps  {
  gameState: GameState;
  wsId: string | null;
}

const PlayerOnline: React.FC<IProps> = ({gameState, wsId}) => {
    const { players } = gameState;
    
    // sort players by score
    players.sort((a, b) => b.score - a.score);
    
    const renderPlayerCard = (player: any) => {
      return (
        <div key={player.id} className={`flex px-3 py-2 ${player.id == wsId ? 'bg-cyan-700' : 'bg-Stroke-01' } w-[205px] rounded-lg justify-between ${player.isDead ? 'opacity-20' : ''}`}>
          <div className={'flex items-center gap-4'}>
            <SnakeHead colorId={player.color} />
            <p className={'text-Text-01 body-1-semibold w-[80%] truncate'}> {player?.username ?? player.id}</p>
          </div>
          <p className={'body-1-semibold text-Text-03'}>{player.score}</p>
        </div>
      )
    }

    return (
        <div className={'bg-BG-01 rounded-lg px-3 h-[585px] max-[1080px]:h-auto max-[1080px]:pb-10'}>
          <div className={'jersey-15-regular text-[32px] text-Text-01 text-center'}>PLAYER ONLINE</div>
          <div className={'flex flex-col max-[1080px]:flex-row max-[1080px]:flex-wrap gap-2'}>
            {players.map((player) => (
              renderPlayerCard(player)
            ))}
          </div>
        </div>
    );
};

export default PlayerOnline;
