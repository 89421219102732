
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';

const CheckWalletRoute: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation(); // Lấy thông tin vị trí hiện tại
  
  useEffect(() => {
    const checkWallet = async () => {
      try {
        const savedWallet = localStorage.getItem('wallet');
        if (!savedWallet) {
          setIsAuthenticated(false);
          return;
        }
        
        const walletData = JSON.parse(savedWallet);
        
        // Kiểm tra thông tin người dùng từ API
        const res = await axiosInstance.get(`/players/profile/${walletData.address}`);
        
        if (res.status === 200) {
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem('wallet');
          setIsAuthenticated(false);
        }
      } catch (error: any) {
        console.error('Failed to reconnect wallet:', error);
        localStorage.removeItem('wallet');
        setIsAuthenticated(false);
      }
    };
    
    checkWallet();
  }, [location]);
  
  if (isAuthenticated === null) {
    // Hiển thị loader hoặc thông báo đang tải
    return <div>Loading...</div>; // Bạn có thể thay bằng component Loader tùy thích
  }
  
  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default CheckWalletRoute;
