import { configureStore } from '@reduxjs/toolkit';
import gameReducer from './gameSlice';
import { ReduxGameState } from '../types/store';

export const store = configureStore({
  reducer: {
    game: gameReducer,
  },
});

export interface RootState {
  game: ReduxGameState;
}

export type AppDispatch = typeof store.dispatch;
