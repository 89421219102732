import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #1a1d21;
`;

const ContentBox = styled.div`
  background-color: #242830;
  padding: 32px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
`;

const ErrorTitle = styled.h1`
  color: #ffffff;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
`;

const ErrorMessage = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  max-width: 300px;
  line-height: 1.5;
  margin-bottom: 24px;
`;

const TwitterLink = styled.a`
  background-color: #98ffb3;
  color: #000000;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  
  &:hover {
    opacity: 0.9;
  }
`;

const ErrorPage = () => {
  return (
    <ErrorContainer>
      <ContentBox>
        <ErrorTitle>Ether Eater is unavailable in your country</ErrorTitle>
        <ErrorMessage>
          Our systems have identified that Ether Eater is not available to play in your country. Please follow us on X for future updates on the game.
        </ErrorMessage>
        <TwitterLink href="https://twitter.com/playethereater" target="_blank">
          @playethereater
        </TwitterLink>
      </ContentBox>
    </ErrorContainer>
  );
};

export default ErrorPage;
