// create a map of colors
import imageResources from '../assets/image';

export interface IMapColors {
  [key: string]: string;
}

export const mapColors: IMapColors = {
  1: '#9D85DF',
  2: '#7B81F1',
  3: '#64ACEC',
  4: '#5DD5A2',
  5: '#8EDF7E',
  6: '#CCDA71',
  7: '#DABB71',
  8: '#E6A285',
  9: '#DF8585',
  10: '#DF85A6',
};

export const colorToSnakeImage = {
  '#9D85DF': imageResources.snake1,
  '#7B81F1': imageResources.snake2,
  '#64ACEC': imageResources.snake3,
  '#5DD5A2': imageResources.snake4,
  '#8EDF7E': imageResources.snake5,
  '#CCDA71': imageResources.snake6,
  '#DABB71': imageResources.snake7,
  '#E6A285': imageResources.snake8,
  '#DF8585': imageResources.snake9,
  '#DF85A6': imageResources.snake10,
}