import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import App from './App';
import './index.scss';
import { CONFIG } from './config';
import { store } from './store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

if (!CONFIG.WS_URL) {
  console.error('WebSocket URL is not configured');
}

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: 'Courier New', monospace;
        background: #1a2b1e;
        color: #4CAF50;
    }
`;

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);


root.render(
  <Provider store={store}>
    <GlobalStyle />
    <ToastContainer
      limit={1}
      theme={'dark'}
    />
    <App />
  </Provider>
);
