import { useEffect, useState } from 'react';

function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(false);
  
  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    
    // Update the matches state whenever the media query status changes
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    
    // Set the initial match status
    setMatches(mediaQueryList.matches);
    
    // Listen for changes to the media query
    mediaQueryList.addEventListener('change', handleMediaQueryChange);
    
    // Clean up the event listener on component unmount
    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange);
    };
  }, [query]);
  
  return matches;
}

export default useMediaQuery;
