import React from 'react';
import imageResources from '../../assets/image';

interface LeaderboardRowProps {
  rank: number;
  name: string;
  gamesWon: number;
  ethEarned: number;
  userInfo: any;
}

const LeaderboardRow: React.FC<LeaderboardRowProps> = ({ rank, name, gamesWon, ethEarned, userInfo }) => {
  const rankRender =
    rank === 1 ? (
      <img className={'mx-auto'} src={imageResources.rank1} alt="" />
    ) : rank === 2 ? (
      <img className={'mx-auto'} src={imageResources.rank2} alt="" />
    ) : rank === 3 ? (
      <img className={'mx-auto'} src={imageResources.rank3} alt="" />
    ) : (
      rank
    );

  return (
    <tr
      className={``}
    >
      <td className={`py-1 px-4 font-bold text-[32px] jersey-15-regular text-Text-01 text-center`}>{rankRender}</td>
      <td className="py-1 px-4 truncate text-[32px] jersey-15-regular text-Text-03 bg-BG-02 rounded-tl-md rounded-bl-md">{name == userInfo?.wallet_address ? 'YOU' : name}</td>
      <td className="py-1 px-4 text-[32px] jersey-15-regular text-Text-03 bg-BG-02">{gamesWon}</td>
      <td className="py-1  px-4 flex items-center justify-end gap-2 bg-BG-02 rounded-br-md rounded-tr-md">
        <span className={'text-[#A59EFA] text-[32px] jersey-15-regular'}>{ethEarned}</span>
        <img src={imageResources.singleRuby} alt="" />
      </td>
    </tr>
  );
};

export default LeaderboardRow;
