import React from 'react';
import imageResources from '../../assets/image';

interface TableRowProps {
  result: string;
  rewardValue: string;
  time: string;
  highlight: boolean;
}

const TableRow: React.FC<TableRowProps> = ({ result, rewardValue, time, highlight }) => {
  return (
    <tr className={`bg-BG-02 hover:bg-gray-600 transition duration-200`}>
      <td className={`rounded-tl-md rounded-bl-md px-4 py-2 jersey-15-regular text-[32px] leading-tight ${!highlight ? 'opacity-30 text-Text-01' : 'text-Text-03'}`}>{result == 'TOP 1' && !rewardValue.includes('-') ? 'WINNER' : result}</td>
      <td className={`justify-end px-4 py-2 jersey-15-regular text-[32px] leading-tight text-[#A59EFA] ${!highlight ? ' opacity-30' : ''} flex items-center gap-1`}>
        <span className="inline-block">{parseFloat(Number(rewardValue ?? 0).toFixed(5))}</span>
        <span className="text-xl w-[16.3px]"><img src={imageResources.singleRuby} alt="" /></span>
      </td>
      <td className="rounded-br-md rounded-tr-md px-4 py-2 text-right jersey-15-regular text-[32px] leading-tight text-Text-03">{time}</td>
    </tr>
  );
};
export default TableRow;