import React from 'react';
import styled from 'styled-components';
import imageResources from '../../assets/image';
import { useNavigate } from 'react-router';
import { formatScore } from '../../utils/formatScore';
import { formatScoreV2 } from '../../utils/formatScoreV2';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #282c34;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  color: white;
`;

const WinnerText = styled.h2`
  color: #ffd700;
  margin-bottom: 1rem;
`;

const ScoreText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
`;

const PlayAgainButton = styled.button`
  padding: 0.8rem 1.5rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #45a049;
  }
`;

interface WinModalProps {
  winner: {
    name: string;
    score: number;
  };
  onPlayAgain: () => void;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  isWin?: boolean;
  player?: any;
  windata?: any;
  allowKeepPlaying?: boolean;
  pressedKeepPlaying?: React.Dispatch<React.SetStateAction<boolean>>;
}

const WinModalV2: React.FC<WinModalProps> = ({ winner, onPlayAgain, isWin, closeModal, player, windata, allowKeepPlaying, pressedKeepPlaying }) => {
  // useNavigate
  const navigation = useNavigate();
  return (
    <ModalOverlay>
      <div className={'bg-BG-02 mx-4 sm:mx-0 rounded-[32px] px-[32px] pb-[32px] pt-[16px] max-w-[420px]'}>
        {/*<WinnerText>🏆 {winner.name} Wins! 🏆</WinnerText>*/}
        {/*<ScoreText>Final Score: {winner.score}</ScoreText>*/}
        {/*<PlayAgainButton onClick={onPlayAgain}>*/}
        {/*    Play Again*/}
        {/*</PlayAgainButton>*/}
        <div className={'flex items-center justify-center gap-3'}>
          {isWin ? (
            <>
              <img src={imageResources.singleRuby} alt="" />
              <p className={'jersey-15-regular text-[60px] sm:text-[80px] text-Text-01'}>YOU WIN</p>
              <img src={imageResources.singleRuby} alt="" />
            </>
          ) : (
            <p className={'jersey-15-regular text-[60px] sm:text-[80px] text-Text-01'}>YOU LOSE</p>
          )}
        </div>
        <div className={'relative w-[70%] max-w-[183px] mx-auto'}>
          <img src={imageResources.cardPlay} alt="" />
          <div className={'absolute flex flex-col w-full h-full top-0 left-0 justify-between items-center py-3'}>
            <p className={'jersey-15-regular text-Text-03 text-[5vw]  sm:text-[20px] md:text-[32px] text-center'}>{player?.score} point</p>
            <img className={'w-[70%]'} src={imageResources[`snake${player?.color}` as keyof typeof imageResources]} alt="" />
            <p className={'jersey-15-regular text-[5vw]  sm:text-[20px] md:text-[32px] text-Text-01 text-center w-[80%] truncate'}>
              {player?.walletAddress}
            </p>
          </div>
        </div>
        {isWin && (
          <>
            <div className={'flex items-center justify-center gap-2 mt-2'}>
              <p className={'text-[#A59EFA] jersey-15-regular text-[32px]'}>REWARD: +{windata?.prizeAmount}</p>
              <img src={imageResources.singleRuby} alt="" />
            </div>
            <p className={'text-Text-01 body-3-regular text-center'}>Claim your reward on the “profile” tab</p>
          </>
        )}

        <button onClick={() => {
          window.location.href = '/';
        }} className={'relative mx-auto self-center block mt-[20px]'}>
          <img src={imageResources.frameButtonLg} alt="" />
          <div className={'absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full'}>
            <p className={'jersey-15-regular text-Text-01 text-[40px] leading-tight'}>Play Again</p>
            <p className={'jersey-15-regular text-Text-01 text-[28px] leading-tight'}>0.001 ETH</p>
          </div>
        </button>
        <div className={'flex items-center gap-4'}>
          <button
            onClick={() => {
              // redirect to home
              window.location.href = '/';
            }}
            className={'relative mx-auto self-center block mt-[20px]'}>
            <img className={''} src={imageResources.backToHome} alt="" />
          </button>
          {!isWin && (
            <button
              disabled={!allowKeepPlaying}
              onClick={() => {
                closeModal(false);
                if (pressedKeepPlaying) {
                  pressedKeepPlaying(true);
                }
              }}
              className={`relative mx-auto self-center block mt-[20px] ${!allowKeepPlaying ? 'cursor-not-allowed opacity-40' : ''}`}>
              <img src={imageResources.keepWatching} alt="" />
            </button>
          )}
        </div>
      </div>
    </ModalOverlay>
  );
};

export default WinModalV2;
