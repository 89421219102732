import React from 'react';
import Layout from '../../components/Layout';
import imageResources from '../../assets/image';


const PrivacyPolicy: React.FC = () => {
  
  return (
    <Layout>
      <div className={'container mx-auto'}>
        <div className={'content'}>
          <div className={'blackArea !pt-[5vh]'}>
            <div
              className={
                'container overflow-hidden mx-5 sm:mx-10 md:mx-auto pt-2 border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
              }
            >
              <div
                className={
                  'flex justify-between items-center pb-2 border-Stroke-01 border-b-[8px] px-[23px]'
                }
              >
                <p
                  className={
                    'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                  }
                >
                  PRIVACY POLICY
                </p>
                <p className={'body-2-semibold text-Text-03'}>
                  Last Updated: 10/12/2024
                </p>
              </div>
              
              <div className={'bg-BG-01'}>
                <div
                  className="pt-[40px] pb-[62px] px-[24px] max-h-[597px] overflow-y-auto body-2-semibold text-Text-03">
                  <div className={'flex items-center gap-4'}>
                    <svg className={'flex-shrink-0'} width="56" height="65" viewBox="0 0 56 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_191_86557)">
                        <path d="M8.52996 4.76538H4.26465V9.03069H8.52996V4.76538Z" fill="#A59EFA" />
                        <path d="M12.7956 4.76538H8.53027V9.03069H12.7956V4.76538Z" fill="#A59EFA" />
                        <path d="M17.0661 0.5H12.8008V4.76531H17.0661V0.5Z" fill="#A59EFA" />
                        <path d="M21.3317 0.5H17.0664V4.76531H21.3317V0.5Z" fill="#A59EFA" />
                        <path d="M25.5973 0.5H21.332V4.76531H25.5973V0.5Z" fill="#A59EFA" />
                        <path d="M29.8669 0.5H25.6016V4.76531H29.8669V0.5Z" fill="#A59EFA" />
                        <path d="M42.6647 0.5H38.3994V4.76531H42.6647V0.5Z" fill="#A59EFA" />
                        <path d="M46.9294 4.76538H42.6641V9.03069H46.9294V4.76538Z" fill="#A59EFA" />
                        <path d="M51.1989 4.76538H46.9336V9.03069H51.1989V4.76538Z" fill="#A59EFA" />
                        <path d="M4.26433 9.03571H-0.000976562V13.301H4.26433V9.03571Z" fill="#A59EFA" />
                        <path d="M4.26433 4.76538H-0.000976562V9.03069H4.26433V4.76538Z" fill="#A59EFA" />
                        <path d="M4.26433 13.3011H-0.000976562V17.5664H4.26433V13.3011Z" fill="#A59EFA" />
                        <path d="M4.26433 17.5663H-0.000976562V21.8317H4.26433V17.5663Z" fill="#A59EFA" />
                        <path d="M4.26433 21.8318H-0.000976562V26.0971H4.26433V21.8318Z" fill="#A59EFA" />
                        <path d="M4.26433 26.1021H-0.000976562V30.3674H4.26433V26.1021Z" fill="#A59EFA" />
                        <path d="M4.26433 30.3673H-0.000976562V34.6326H4.26433V30.3673Z" fill="#A59EFA" />
                        <path d="M4.26433 34.6328H-0.000976562V38.8981H4.26433V34.6328Z" fill="#A59EFA" />
                        <path d="M4.26433 38.8979H-0.000976562V43.1633H4.26433V38.8979Z" fill="#A59EFA" />
                        <path d="M8.52996 47.4337H4.26465V51.699H8.52996V47.4337Z" fill="#A59EFA" />
                        <path d="M4.26433 43.1683H-0.000976562V47.4336H4.26433V43.1683Z" fill="#A59EFA" />
                        <path d="M12.7956 51.699H8.53027V55.9643H12.7956V51.699Z" fill="#A59EFA" />
                        <path d="M17.0661 55.9643H12.8008V60.2296H17.0661V55.9643Z" fill="#A59EFA" />
                        <path d="M21.3317 60.2347H17.0664V64.5H21.3317V60.2347Z" fill="#A59EFA" />
                        <path d="M25.5973 60.2347H21.332V64.5H25.5973V60.2347Z" fill="#A59EFA" />
                        <path d="M29.8669 60.2347H25.6016V64.5H29.8669V60.2347Z" fill="#A59EFA" />
                        <path d="M34.1325 0.5H29.8672V4.76531H34.1325V0.5Z" fill="#A59EFA" />
                        <path d="M17.0661 13.3011H12.8008V17.5664H17.0661V13.3011Z" fill="#A59EFA" />
                        <path d="M17.0661 17.5664H12.8008V21.8317H17.0661V17.5664Z" fill="#A59EFA" />
                        <path d="M17.0661 21.8317H12.8008V26.097H17.0661V21.8317Z" fill="#A59EFA" />
                        <path d="M17.0661 26.1021H12.8008V30.3674H17.0661V26.1021Z" fill="#A59EFA" />
                        <path d="M17.0661 30.3674H12.8008V34.6327H17.0661V30.3674Z" fill="#A59EFA" />
                        <path d="M17.0661 34.6327H12.8008V38.898H17.0661V34.6327Z" fill="#A59EFA" />
                        <path d="M17.0661 38.8979H12.8008V43.1633H17.0661V38.8979Z" fill="#A59EFA" />
                        <path d="M21.3317 43.1683H17.0664V47.4336H21.3317V43.1683Z" fill="#A59EFA" />
                        <path d="M25.5973 47.4337H21.332V51.699H25.5973V47.4337Z" fill="#A59EFA" />
                        <path d="M29.8669 47.4337H25.6016V51.699H29.8669V47.4337Z" fill="#A59EFA" />
                        <path d="M34.1325 43.1683H29.8672V47.4336H34.1325V43.1683Z" fill="#A59EFA" />
                        <path d="M34.1325 47.4337H29.8672V51.699H34.1325V47.4337Z" fill="#A59EFA" />
                        <path d="M42.6647 38.8979H38.3994V43.1633H42.6647V38.8979Z" fill="#A59EFA" />
                        <path d="M42.6647 34.6326H38.3994V38.8979H42.6647V34.6326Z" fill="#A59EFA" />
                        <path d="M42.6647 30.3674H38.3994V34.6327H42.6647V30.3674Z" fill="#A59EFA" />
                        <path d="M42.6647 26.1021H38.3994V30.3674H42.6647V26.1021Z" fill="#A59EFA" />
                        <path d="M42.6647 21.8317H38.3994V26.097H42.6647V21.8317Z" fill="#A59EFA" />
                        <path d="M42.6647 17.5663H38.3994V21.8317H42.6647V17.5663Z" fill="#A59EFA" />
                        <path d="M21.3317 13.3011H17.0664V17.5664H21.3317V13.3011Z" fill="#A59EFA" />
                        <path d="M25.5973 13.301H21.332V17.5663H25.5973V13.301Z" fill="#A59EFA" />
                        <path d="M29.8669 13.3011H25.6016V17.5664H29.8669V13.3011Z" fill="#A59EFA" />
                        <path d="M42.6647 13.301H38.3994V17.5663H42.6647V13.301Z" fill="#A59EFA" />
                        <path d="M34.1325 13.3011H29.8672V17.5664H34.1325V13.3011Z" fill="#A59EFA" />
                        <path d="M21.3317 17.5664H17.0664V21.8317H21.3317V17.5664Z" fill="#A59EFA" />
                        <path d="M25.5973 17.5664H21.332V21.8317H25.5973V17.5664Z" fill="#A59EFA" />
                        <path d="M34.1325 17.5664H29.8672V21.8317H34.1325V17.5664Z" fill="#A59EFA" />
                        <path d="M25.5973 21.8317H21.332V26.097H25.5973V21.8317Z" fill="#A59EFA" />
                        <path d="M34.1325 21.8317H29.8672V26.097H34.1325V21.8317Z" fill="#A59EFA" />
                        <path d="M21.3317 21.8318H17.0664V26.0971H21.3317V21.8318Z" fill="#A59EFA" />
                        <path d="M21.3317 30.3673H17.0664V34.6326H21.3317V30.3673Z" fill="#A59EFA" />
                        <path d="M25.5973 30.3674H21.332V34.6327H25.5973V30.3674Z" fill="#A59EFA" />
                        <path d="M34.1325 30.3674H29.8672V34.6327H34.1325V30.3674Z" fill="#A59EFA" />
                        <path d="M21.3317 34.6328H17.0664V38.8981H21.3317V34.6328Z" fill="#A59EFA" />
                        <path d="M25.5973 34.6326H21.332V38.8979H25.5973V34.6326Z" fill="#A59EFA" />
                        <path d="M34.1325 34.6327H29.8672V38.898H34.1325V34.6327Z" fill="#A59EFA" />
                        <path d="M21.3317 38.8979H17.0664V43.1633H21.3317V38.8979Z" fill="#A59EFA" />
                        <path d="M25.5973 38.8979H21.332V43.1633H25.5973V38.8979Z" fill="#A59EFA" />
                        <path d="M25.5973 43.1684H21.332V47.4337H25.5973V43.1684Z" fill="#A59EFA" />
                        <path d="M29.8669 43.1683H25.6016V47.4336H29.8669V43.1683Z" fill="#A59EFA" />
                        <path d="M34.1325 38.8979H29.8672V43.1633H34.1325V38.8979Z" fill="#A59EFA" />
                        <path d="M34.1325 60.2347H29.8672V64.5H34.1325V60.2347Z" fill="#A59EFA" />
                        <path d="M38.3981 0.5H34.1328V4.76531H38.3981V0.5Z" fill="#A59EFA" />
                        <path d="M38.3981 43.1683H34.1328V47.4336H38.3981V43.1683Z" fill="#A59EFA" />
                        <path d="M38.3981 13.3011H34.1328V17.5664H38.3981V13.3011Z" fill="#A59EFA" />
                        <path d="M38.3981 17.5664H34.1328V21.8317H38.3981V17.5664Z" fill="#A59EFA" />
                        <path d="M38.3981 21.8318H34.1328V26.0971H38.3981V21.8318Z" fill="#A59EFA" />
                        <path d="M38.3981 30.3673H34.1328V34.6326H38.3981V30.3673Z" fill="#A59EFA" />
                        <path d="M38.3981 34.6328H34.1328V38.8981H38.3981V34.6328Z" fill="#A59EFA" />
                        <path d="M38.3981 38.8979H34.1328V43.1633H38.3981V38.8979Z" fill="#A59EFA" />
                        <path d="M38.3981 60.2347H34.1328V64.5H38.3981V60.2347Z" fill="#A59EFA" />
                        <path d="M46.9294 51.699H42.6641V55.9643H46.9294V51.699Z" fill="#A59EFA" />
                        <path d="M42.6647 55.9642H38.3994V60.2295H42.6647V55.9642Z" fill="#A59EFA" />
                        <path d="M51.1989 47.4337H46.9336V51.699H51.1989V47.4337Z" fill="#A59EFA" />
                        <path d="M55.4636 43.1683H51.1982V47.4336H55.4636V43.1683Z" fill="#A59EFA" />
                        <path d="M55.4636 38.8979H51.1982V43.1633H55.4636V38.8979Z" fill="#A59EFA" />
                        <path d="M55.4636 34.6327H51.1982V38.898H55.4636V34.6327Z" fill="#A59EFA" />
                        <path d="M55.4636 30.3674H51.1982V34.6327H55.4636V30.3674Z" fill="#A59EFA" />
                        <path d="M55.4636 26.1021H51.1982V30.3674H55.4636V26.1021Z" fill="#A59EFA" />
                        <path d="M55.4636 21.8317H51.1982V26.097H55.4636V21.8317Z" fill="#A59EFA" />
                        <path d="M55.4636 17.5664H51.1982V21.8317H55.4636V17.5664Z" fill="#A59EFA" />
                        <path d="M55.4636 13.3011H51.1982V17.5664H55.4636V13.3011Z" fill="#A59EFA" />
                        <path d="M55.4636 9.03577H51.1982V13.3011H55.4636V9.03577Z" fill="#A59EFA" />
                        <path d="M55.4636 4.76532H51.1982V9.03063H55.4636V4.76532Z" fill="#A59EFA" />
                      </g>
                      <defs>
                        <clipPath id="clip0_191_86557">
                          <rect width="55.4643" height="64" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                    
                    
                    Ethereater respects your privacy and is committed to protecting your personal data. This Privacy
                    Policy outlines how we collect, use, and protect your information when you use Ethereater.
                  </div>
                  <br />
                  <span className={'text-Text-01'}>1. Information Collection</span>
                  <br />We collect only the information necessary to operate Ethereater effectively. This includes data
                  from your Ethereum wallet and basic game interactions. Ethereater does not collect or store sensitive
                  personal information such as your real name, address, or payment information.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>2. Use of Information</span>
                  <br />We use your data solely to facilitate gameplay and improve the Ethereater experience.
                  Transaction and wallet information are used to manage ETH deposits, winnings, and gameplay data
                  securely. We may also use aggregate data for game analytics.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>3. Data Sharing</span>
                  <br />Ethereater will not sell or share your personal data with third parties without your consent,
                  except as required by law or for security purposes.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>4. Data Security</span>
                  <br />We use secure servers and encryption to protect your data. However, please be aware that no
                  online service can guarantee complete data security. By playing Ethereater, you acknowledge the
                  inherent risks associated with blockchain technology.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>5. Third-Party Links</span>
                  <br />Ethereater may contain links to third-party services, such as wallet providers or blockchain
                  explorers. We are not responsible for the privacy practices of these external sites. We encourage you
                  to review their policies independently.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>6. Cookies and Tracking</span>
                  <br />Ethereater may use cookies to improve your experience. Cookies help us monitor site traffic and
                  gameplay analytics. You can disable cookies through your browser settings, though this may impact
                  functionality.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>7. Children’s Privacy</span>
                  <br />Ethereater is intended for users aged 18 or older. We do not knowingly collect information from
                  children under 18. If we become aware of any such data, we will delete it promptly.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>8. Your Data Rights</span>
                  <br />You have the right to access, modify, or delete any personal data Ethereater holds about you.
                  For any data-related requests, please contact us at support@ethereater.com.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>9. Changes to the Privacy Policy</span>
                  <br />We may update this Privacy Policy to reflect changes to our practices. Continued use of
                  Ethereater constitutes your acceptance of these changes.
                  <br />
                  <br />
                  <span className={'text-Text-01'}>10. Contact Us</span>
                  <br />For questions or concerns about our Privacy Policy, please email us at support@ethereater.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
