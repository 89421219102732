import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { GameProps, GameWebSocket, GameState } from './../../types/game';
import Layout from '../../components/Layout';
import PlayerOnline from '../../components/PlayerOnline/PlayerOnline';
import styles from './Game.module.css';
import imageResources from '../../assets/image';
import { mapColors } from '../../utils/mapColors';
import WinModalV2 from '../../components/WinModal/WinModalV2';
import { useNavigate } from 'react-router';
import LinkConfirm from '../../components/LinkConfirm';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserAction } from '../../store/gameSlice';
import useDebounce from '../../hooks/useDebouce';

interface WinData {
  winner: any;
  prizeAmount: number;
  platformFee: number;
  timestamp: string;
}

interface ModalContent {
  type: 'win' | 'lose';
  message?: string;
  showWinner?: boolean;
  winnerName?: string;
}

const Game: React.FC<GameProps> = ({ ws, gameState, wsId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  if (!location.state?.ws) {
    window.location.href = '/';;
  }
  const logRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const TILE_SIZE = 16;
  const GRID_SIZE = 60;
  const GRID_Y_SIZE = 43;
  const [direction, setDirection] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const directionRef = useRef<string>('');
  const [winData, setWinData] = useState<WinData | null>(null);
  const [gameLog, setGameLog] = useState<any[]>([]);
  const [allowKeepPlaying, setAllowKeepPlaying] = useState<boolean>(true);
  const [isPressKeepPlaying, setIsPressKeepPlaying] = useState<boolean>(false);
  const [redirectCountdown, setRedirectCountdown] = useState<number | null>(
    null,
  );
  const [countdown, setCountdown] = useState<number | null>(null);
  const [willRewarded, setWillRewarded] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Game received state update:', gameState);
    render();
  }, [gameState]);

  useEffect(() => {
    directionRef.current = direction;
  }, [direction]);

  useEffect(() => {
    if (!gameState.isStarted) return;

    // Không gửi lệnh 'move' liên tục nữa
    // Chỉ gửi lệnh 'change_direction' khi người chơi thay đổi hướng
  }, [ws, gameState.isStarted]);

  // Lắng nghe các thông điệp từ server
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'countdown_start') {
          setCountdown(data.timeLeft);
        } else if (data.type === 'countdown_update_ingame') {
          setCountdown(data.timeLeft);
          setWillRewarded(data?.countPlayers)
        } else if (data.type === 'countdown_end') {
          setCountdown(null);
          // Có thể hiển thị thông báo rằng game đã bắt đầu
        } else if (data.type === 'game_ended') {
          console.log(
            'Game ended:',
            data,
            '____',
            wsId,
            '___',
            data?.player?.id,
          );
          if (data?.winData) {
            setAllowKeepPlaying(false);
            setTimeout(() => {
              window.location.href = '/';
            }, 10000);
          }
          if (wsId == data?.player?.id) {
            if (data?.result === 'win') {
              setShowModal(true);
              setModalContent({
                type: 'win',
                message: data.message,
                winData: data.winData,
                winner: data.winner,
                player: data.player,
              });
            } else {
              setShowModal(true);
              setModalContent({
                type: 'lose',
                message: 'You lost the game!',
                player: data.player,
              });
            }
            // Gửi message ready cho game mới thay vì đóng ws
            ws.send(JSON.stringify({
              type: "ready_for_new_game",
              walletAddress: data.player.walletAddress
            }));
          }
        } else if (data.type === 'player_update') {
          const every = data?.players?.every((player: any) => player?.isDead === true);
          if (every) {
            setAllowKeepPlaying(false);
            setTimeout(() => {
              window.location.href = '/';
            }, 10000);
          }
          render();
        } else if (data.type === 'eth_update') {
          render();
        } else if (data.type === 'game_log') {
          setGameLog((prev) => [...prev, data]);
        } else if (data.type === 'redirect_home') {
          dispatch(setUserAction({
            confirmExit: false,
          }));
        }
      } catch (error) {
        console.error('Error handling message:', error);
      }
    };

    ws.addEventListener('message', handleMessage);
    return () => ws.removeEventListener('message', handleMessage);
  }, [ws, gameState]);

  // Countdown effect
  useEffect(() => {
    if (redirectCountdown === null) return;

    const timer = setInterval(() => {
      setRedirectCountdown((prev) =>
        prev !== null && prev > 0 ? prev - 1 : null,
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [redirectCountdown]);
  
  // Tạo hàm debounce cho logic xử lý sự kiện chính
  // Remove useCallback wrapping
  const debouncedHandleKeyPress = useDebounce((event: KeyboardEvent) => {
    if (countdown !== null) {
      return; // Do not allow movement during countdown
    }
    
    const currentDirection = directionRef.current;
    let newDirection = currentDirection;
    
    switch (event.key.toLowerCase()) {
      case 'w':
      case 'arrowup':
        if (currentDirection !== 'down') newDirection = 'up';
        break;
      case 's':
      case 'arrowdown':
        if (currentDirection !== 'up') newDirection = 'down';
        break;
      case 'a':
      case 'arrowleft':
        if (currentDirection !== 'right') newDirection = 'left';
        break;
      case 'd':
      case 'arrowright':
        if (currentDirection !== 'left') newDirection = 'right';
        break;
    }
    
    if (newDirection !== currentDirection) {
      setDirection(newDirection);
      
      // Check WebSocket before sending
      console.log('Sending new direction:', newDirection);
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(
          JSON.stringify({
            type: 'change_direction',
            direction: newDirection,
          })
        );
      } else {
        console.error('WebSocket is not open');
      }
    }
  }, 125);
  
  
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        ['arrowup', 'arrowdown', 'arrowleft', 'arrowright', 'w', 's', 'a', 'd'].includes(
          event.key.toLowerCase()
        )
      ) {
        event.preventDefault();
        debouncedHandleKeyPress(event);
      }
    };
    
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [debouncedHandleKeyPress]);
  
  
  const drawRoundedRect = (
    ctx: any,
    x: any,
    y: any,
    width: any,
    height: any,
    radius: any,
    isHead?: boolean,
    direction?: string,
    isOwner?: boolean,
  ) => {
    ctx.beginPath();

    if (isHead) {
      // Adjust rounding based on the direction
      switch (direction) {
        case 'up':
          // Round top corners more
          ctx.moveTo(x + radius * 2, y);
          ctx.lineTo(x + width - radius * 2, y);
          ctx.arcTo(x + width, y, x + width, y + radius * 2, radius * 2);
          ctx.lineTo(x + width, y + height - radius);
          ctx.arcTo(
            x + width,
            y + height,
            x + width - radius,
            y + height,
            radius,
          );
          ctx.lineTo(x + radius, y + height);
          ctx.arcTo(x, y + height, x, y + height - radius, radius);
          ctx.lineTo(x, y + radius * 2);
          ctx.arcTo(x, y, x + radius * 2, y, radius * 2);
          break;

        case 'down':
          // Round bottom corners more
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius, y);
          ctx.arcTo(x + width, y, x + width, y + radius, radius);
          ctx.lineTo(x + width, y + height - radius * 2);
          ctx.arcTo(
            x + width,
            y + height,
            x + width - radius * 2,
            y + height,
            radius * 2,
          );
          ctx.lineTo(x + radius * 2, y + height);
          ctx.arcTo(x, y + height, x, y + height - radius * 2, radius * 2);
          ctx.lineTo(x, y + radius);
          ctx.arcTo(x, y, x + radius, y, radius);
          break;

        case 'left':
          // Round left corners more
          ctx.moveTo(x + radius * 2, y);
          ctx.lineTo(x + width - radius, y);
          ctx.arcTo(x + width, y, x + width, y + radius, radius);
          ctx.lineTo(x + width, y + height - radius);
          ctx.arcTo(
            x + width,
            y + height,
            x + width - radius,
            y + height,
            radius,
          );
          ctx.lineTo(x + radius * 2, y + height);
          ctx.arcTo(x, y + height, x, y + height - radius, radius * 2);
          ctx.lineTo(x, y + radius);
          ctx.arcTo(x, y, x + radius * 2, y, radius * 2);
          break;

        case 'right':
        default:
          // Round right corners more
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius * 2, y);
          ctx.arcTo(x + width, y, x + width, y + radius * 2, radius * 2);
          ctx.lineTo(x + width, y + height - radius);
          ctx.arcTo(
            x + width,
            y + height,
            x + width - radius * 2,
            y + height,
            radius * 2,
          );
          ctx.lineTo(x + radius, y + height);
          ctx.arcTo(x, y + height, x, y + height - radius, radius);
          ctx.lineTo(x, y + radius);
          ctx.arcTo(x, y, x + radius, y, radius);
          break;
      }
    } else {
      // Draw regular rounded rectangle for body segments
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.arcTo(x + width, y, x + width, y + radius, radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
      ctx.lineTo(x + radius, y + height);
      ctx.arcTo(x, y + height, x, y + height - radius, radius);
      ctx.lineTo(x, y + radius);
      ctx.arcTo(x, y, x + radius, y, radius);
    }

    ctx.closePath();
    ctx.fill();
  };

  const render = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Clear canvas
    ctx.clearRect(0, 0, GRID_SIZE * TILE_SIZE, GRID_Y_SIZE * TILE_SIZE);

    // Draw background
    ctx.fillStyle = '#24453A';
    ctx.fillRect(0, 0, GRID_SIZE * TILE_SIZE, GRID_Y_SIZE * TILE_SIZE);

    // Draw grid
    ctx.strokeStyle = '#1D2C29';
    ctx.lineWidth = 1;
    for (let i = 0; i <= GRID_SIZE; i++) {
      ctx.beginPath();
      ctx.moveTo(i * TILE_SIZE, 0);
      ctx.lineTo(i * TILE_SIZE, GRID_Y_SIZE * TILE_SIZE);
      ctx.stroke();
    }

    for (let j = 0; j <= GRID_Y_SIZE; j++) {
      ctx.beginPath();
      ctx.moveTo(0, j * TILE_SIZE);
      ctx.lineTo(GRID_SIZE * TILE_SIZE, j * TILE_SIZE);
      ctx.stroke();
    }

    // Draw players with rounded corners
    console.log(gameState.players, 'gameState.players');
    console.log(gameState, 'gameState');
    gameState.players.forEach((player) => {
      const color = mapColors[player.color];

      if (player?.isDead) return;

      ctx.fillStyle = color;
      
      if (player?.id === wsId) {
        ctx.shadowColor = 'rgba(127,255,244,0.5)'; // Màu của glow (ví dụ: màu xanh lam)
        ctx.shadowBlur = 15; // Mức độ mờ của glow
        ctx.shadowOffsetX = 0; // Độ lệch ngang của shadow
        ctx.shadowOffsetY = 0; // Độ lệch dọc của shadow
      } else {
        // unset shadow
        ctx.shadowBlur = 0;
      }

      player.body.forEach((segment: any, index: number) => {
        const x = segment.x * TILE_SIZE + 1;
        const y = segment.y * TILE_SIZE + 1;
        const width = TILE_SIZE - 1;
        const height = TILE_SIZE - 1;
        const radius = 2;

        if (index === player.body.length - 1) {
          drawRoundedRect(
            ctx,
            x,
            y,
            width,
            height,
            radius,
            true,
            player.direction,
            player.id == wsId,
          );

          ctx.fillStyle = '#1a1a2e';
          const eyeSize = 2;
          // Eye position variables
          let eyeOffsetX1 = 0;
          let eyeOffsetY1 = 0;
          let eyeOffsetX2 = 0;
          let eyeOffsetY2 = 0;

          // Adjust eye position based on direction
          switch (player.direction) {
            case 'up':
              eyeOffsetX1 = 4;
              eyeOffsetY1 = 4;
              eyeOffsetX2 = 9;
              eyeOffsetY2 = 4;
              break;
            case 'down':
              eyeOffsetX1 = 4;
              eyeOffsetY1 = height - 6;
              eyeOffsetX2 = 9;
              eyeOffsetY2 = height - 6;
              break;
            case 'left':
              eyeOffsetX1 = 4;
              eyeOffsetY1 = 4;
              eyeOffsetX2 = 4;
              eyeOffsetY2 = 9;
              break;
            case 'right':
            default:
              eyeOffsetX1 = width - 6;
              eyeOffsetY1 = 4;
              eyeOffsetX2 = width - 6;
              eyeOffsetY2 = 9;
              break;
          }

          // Draw eyes
          ctx.fillRect(x + eyeOffsetX1, y + eyeOffsetY1, eyeSize, eyeSize);
          ctx.fillRect(x + eyeOffsetX2, y + eyeOffsetY2, eyeSize, eyeSize);

          ctx.fillStyle = color;
        } else {
          drawRoundedRect(ctx, x, y, width, height, radius);
        }
      });
    });

    // Draw tokens
    if (gameState.tokens && gameState.tokens.length > 0) {
      gameState.tokens.forEach((token: any) => {
        const centerX = token.x * TILE_SIZE + TILE_SIZE / 2;
        const centerY = token.y * TILE_SIZE + TILE_SIZE / 2;
        const size = TILE_SIZE / 3;

        ctx.fillStyle = '#A688FF';

        ctx.beginPath();
        ctx.moveTo(centerX, centerY - size);
        ctx.lineTo(centerX + size, centerY);
        ctx.lineTo(centerX, centerY + size);
        ctx.lineTo(centerX - size, centerY);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
        ctx.beginPath();
        ctx.moveTo(centerX, centerY - size);
        ctx.lineTo(centerX + size / 2, centerY);
        ctx.lineTo(centerX, centerY + size / 2);
        ctx.closePath();
        ctx.fill();

        // Reset shadow
        ctx.shadowBlur = 0;
      });
    }
  };

  // Modal component
  const GameModal = useMemo(() => {
    if (!showModal || !modalContent || isPressKeepPlaying) return null;

    return (
      <WinModalV2
        winner={modalContent.winner}
        windata={modalContent?.winData}
        player={modalContent.player}
        onPlayAgain={() => {
          setShowModal(false);
          setModalContent(null);
          setGameLog([]);
        }}
        isWin={modalContent.type === 'win'}
        closeModal={setShowModal}
        allowKeepPlaying={allowKeepPlaying}
        pressedKeepPlaying={setIsPressKeepPlaying}
      />
    );
  }, [showModal, modalContent, allowKeepPlaying, isPressKeepPlaying]);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      setModalContent(null);
      setShowModal(false);
      setWinData(null);
    };
  }, []);

  if (logRef.current) {
    logRef.current.scrollTop = logRef.current.scrollHeight;
  }

  return (
    <Layout>
      <div className={' mx-auto !min-w-[830px]'}>
        <div className={'content'}>
          <div className={'blackArea !p-0'}>
            <div
              className={
                ' overflow-hidden mx-5 sm:mx-10 md:mx-auto border-Stroke-01 rounded-lg bg-BG-02 mt-5'
              }
            >
              <div className={'flex justify-center pb-2 px-[23px] gap-2'}>
                <p
                  className={
                    'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                  }
                >
                  WINNER REWARD: +{willRewarded * 0.0009}
                </p>
                <img src={imageResources.singleRuby} alt="" />
              </div>
              <div className={'flex max-[1080px]:flex-col-reverse px-4 gap-4'}>
                <div className={styles.gameArea}>
                  <canvas
                    ref={canvasRef}
                    width={GRID_SIZE * TILE_SIZE}
                    height={GRID_Y_SIZE * TILE_SIZE}
                  />
                  {countdown !== null && (
                    <div className={styles.countdownOverlay}>
                      <img src={imageResources.countDown} alt="" />
                      <div
                        className={
                          'absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2  jersey-15-regular text-[80px] text-Text-01'
                        }
                      >
                        {countdown}
                      </div>
                    </div>
                  )}
                  {GameModal}
                  <div
                    ref={logRef}
                    className={
                      'flex flex-wrap justify-center gap-x-4 overflow-y-auto max-h-[44px] mt-2 mb-4 min-h-[44px] max-w-[960px]'
                    }
                  >
                    {gameLog.map((log, index) => (
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{ __html: log?.message ?? '' }}
                      />
                    ))}
                  </div>
                </div>
                <div
                  className={
                    'flex flex-col max-[1080px]:flex-col-reverse min-w-[229px]'
                  }
                >
                  <PlayerOnline gameState={gameState} wsId={wsId} />
                  <LinkConfirm
                    className={
                      'relative mx-auto my-auto self-center block max-[1080px]:self-start max-[1080px]:mx-0 max-[1080px]:mb-4'
                    }
                    willConfirm={ws?.readyState === WebSocket.OPEN}
                  >
                    <img
                      className={''}
                      src={imageResources.backToHome}
                      alt=""
                    />
                  </LinkConfirm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Game;
