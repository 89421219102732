import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setAuth, setWalletState } from '../../store/gameSlice';
import imageResources from '../../assets/image';
import { ethers } from 'ethers';
import { GameWebSocket } from '../../types/game';
import LinkConfirm from '../LinkConfirm';
import axiosInstance from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

const Navigation_v2: React.FC<{ ws?: GameWebSocket }> = ({ ws }) => {
  const dispatch = useDispatch();
  const wallet = useSelector((state: RootState) => state.game.wallet);
  const navigate = useNavigate();
  const location = useLocation()

  // Kiểm tra và kết nối lại ví khi component mount
  useEffect(() => {
    const connectWalletFromStorage = async () => {
      const savedWallet = localStorage.getItem('wallet');
      if (savedWallet) {
        const walletData = JSON.parse(savedWallet);

        try {
          if (!window.ethereum) return;

          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const accounts = await provider.send("eth_requestAccounts", []);

          if (accounts[0].toLowerCase() === walletData.address.toLowerCase()) {
            const balance = ethers.utils.formatEther(await provider.getBalance(accounts[0]));
            const network = await provider.getNetwork();

            dispatch(setWalletState({
              isConnecting: false,
              isConnected: true,
              address: accounts[0],
              balance,
              network: network.name,
              playerName: walletData.playerName,
            }));
          }
        } catch (error) {
          console.error('Failed to reconnect wallet:', error);
          localStorage.removeItem('wallet');
        }
      }
    };

    connectWalletFromStorage();
  }, [dispatch]);

  const formatAddress = (address: string) => {
    if (!address) return '';
    return `${address.slice(0, 3)}...${address.slice(-3)}`;
  };

  const handleConnect = async () => {
    try {
      if (wallet.isConnected) {
        // Disconnect logic
        dispatch(setWalletState({ isConnected: false, address: null, balance: '0', network: null, playerName: null }));
        dispatch(setAuth({ userInfo: null, accessToken: '' }));
        localStorage.removeItem('wallet');
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('signature');
        if (location.pathname !== '/') {
          navigate('/');
        }
        return;
      }

      if (!window.ethereum) {
        toast.warn('Please install MetaMask!');
        return;
      }

      dispatch(setWalletState({ isConnecting: true }));
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_requestAccounts", []);

      if (accounts.length === 0) {
        throw new Error('No accounts found', { cause: 'NO_ACCOUNTS' });
      }

      const address = accounts[0];
      const balance = ethers.utils.formatEther(await provider.getBalance(address));
      const network: any = await provider.getNetwork();
      const chainId: any = process.env.REACT_APP_CHAIN_ID;
      const networkChainId: any = network?.chainId;
      if (networkChainId != chainId) {
        throw new Error('Please switch to Cyber network', {
          cause: { code: 'INVALID_NETWORK', network: network.chainId, networkName: network.name },
        });
      }

      let playerName = formatAddress(address);
      try {
        const ensName = await provider.lookupAddress(address);
        if (ensName) {
          playerName = ensName;
        }
      } catch (error) {
        console.log('No ENS name found');
      }

      const walletData = { address, playerName, network: network.name };
      localStorage.setItem('wallet', JSON.stringify(walletData));

      const signature = await provider.send("personal_sign", [`Login to Snake Game with address: ${address}`, accounts[0]]);
      const login = await axiosInstance.post('/players/login', {
        wallet_address: address,
        username: playerName,
        signature: signature
      });

      if (login.status === 200) {
        localStorage.setItem('token', login.data?.access_token);
        localStorage.setItem('userInfo', JSON.stringify(login.data?.player));
        localStorage.setItem('signature', signature);
        dispatch(setAuth({ userInfo: login.data?.player, accessToken: login.data?.access_token }));
      } else {
        throw new Error('Failed to login to server');
      }

      dispatch(setWalletState({
        isConnecting: false,
        isConnected: true,
        address,
        balance,
        network: network.name,
        playerName,
        rank: login.data?.rank,
        highScore: login.data?.high_score,
      }));

      // Gửi thông điệp ping định kỳ
      const pingInterval = setInterval(() => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send('ping');
        }
      }, 30000); // 30 giây

      // Dọn dẹp khi component unmount
      return () => clearInterval(pingInterval);

    } catch (error: any) {
      console.error('Failed to connect wallet:', error);
      localStorage.removeItem('wallet');
      if (error?.cause?.code === 'INVALID_NETWORK') {
        toast.warn(<div><p>Please switch to <strong>Cyber  network</strong></p></div>, {
          autoClose: 10000,
          theme: 'dark',
          progressStyle: { backgroundColor: '#A59EFA' },
        });
      } else {
        toast.warn(error?.message || 'Failed to connect wallet');
      }
      dispatch(setWalletState({ isConnecting: false, isConnected: false }));
    }
  };

  return (
    <header className="bg-transparent text-white py-4">
      <div className="mx-auto flex items-center justify-center md:justify-between px-4">
        <div className={`w-[40%] !hidden md:!flex gap-4 menu__navigation `}>
          <LinkConfirm willConfirm={ws?.readyState === WebSocket.OPEN} to="/home" className="flex items-center justify-center">
            <img src={imageResources.game} alt="Game Icon" className="" />
          </LinkConfirm>
          <LinkConfirm willConfirm={ws?.readyState === WebSocket.OPEN} to="/leaderboard" className="flex items-center justify-center">
            <img src={imageResources.ranking} alt="Game Icon" className="" />
          </LinkConfirm>
          <LinkConfirm willConfirm={ws?.readyState === WebSocket.OPEN} to="/profile" className="flex items-center justify-center">
            <img src={imageResources.profile} alt="Game Icon" className="" />
          </LinkConfirm>
          <LinkConfirm willConfirm={ws?.readyState === WebSocket.OPEN} to="/how-to-play" className="flex items-center justify-center">
            <img src={imageResources.info} alt="Game Icon" className="" />
          </LinkConfirm>
        </div>

        <div className="flex items-center">
          <img
            src={imageResources.logo2}
            alt="Ether Eater Logo"
            className="mr-2"
          />
        </div>

        <div className="w-[40%] hidden md:flex max-[1040px]:flex-col flex-row justify-end items-end min-[1040px]:items-center sm:gap-2 md:gap-2">
          <span className="text-2xl sm:text-[24px] jersey-15-regular">
            Balance: {Number(wallet.balance).toFixed(4)} ETH
          </span>
          {wallet.isConnected && wallet.address ? (
            <button
              onClick={handleConnect}
              disabled={wallet.isConnecting}
              className="flex items-center gap-2 relative w-2/3 sm:w-auto"
            >
              <img
                src={imageResources.connected}
                alt="Connect Icon"
                className=""
              />
              <span
                className={
                  'absolute left-[40px] text-ellipsis max-w-[130px] overflow-hidden'
                }
              >
                {formatAddress(wallet.address)}
              </span>
            </button>
          ) : (
            <button
              onClick={handleConnect}
              disabled={wallet.isConnecting}
              className="flex items-center gap-2 w-2/3 sm:w-auto"
            >
              <img
                src={imageResources.connect}
                alt="Connect Icon"
                className=""
              />
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navigation_v2;
