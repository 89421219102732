// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/image/_bg_dot.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_layout__\\+nPRX {
  min-height: 100vh;
  background: #1a1a1a;
  color: #4CAF50;
  font-family: Courier, monospace, sans-serif;
  display: flex;
  flex-direction: column;
}

.Layout_main__FV28D {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
  background-size: cover;
  flex-grow: 1;
  flex-shrink: 0;
}
@media screen and (min-width: 768px) {
  .Layout_main__FV28D {
    min-height: 1011px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.scss","webpack://./src/assets/styles/_theme.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,mBCHc;EDId,cAAA;EACA,2CAAA;EACA,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,2EAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;AAAF;AACE;EALF;IAMI,kBAAA;EAEF;AACF","sourcesContent":["@import \"../../assets/styles/theme\";\n.layout {\n  min-height: 100vh;\n  background: $_colors_BG_01;\n  color: #4CAF50;\n  font-family: $_fontFamily_courier, monospace, sans-serif;\n  display: flex;\n  flex-direction: column;\n}\n\n.main {\n  background: url(\"../../assets/image/_bg_dot.svg\") no-repeat center center;\n  background-size: cover;\n  flex-grow: 1;\n  flex-shrink: 0;\n  @media screen and (min-width: 768px) {\n    min-height: 1011px;\n  };\n} ","$_colors_BG_01: #1a1a1a;\n$_colors_BG_02: #1d2c29;\n$_colors_BG_03: #05a38c;\n$_colors_Stroke_01: #24453a;\n$_colors_Text_01: #e6f4e6;\n$_colors_Text_02: #c4c4c3;\n$_colors_Text_03: #5dd5a2;\n$_fontSize_base: 0.75rem;\n$_fontSize_lg: 0.8125rem;\n$_fontSize_xl: 0.9375rem;\n$_fontSize_2xl: 1.125rem;\n$_fontSize_3xl: 1.5rem;\n$_fontSize_4xl: 2.25rem;\n$_fontSize_5xl: 3rem;\n$_fontSize_6xl: 4rem;\n$_fontFamily_courier: Courier;\n$_fontFamily_jersey: Jersey;\n$_borderRadius_rounded_0: 0rem;\n$_borderRadius_rounded_1: 0.3333333333333333rem;\n$_borderRadius_rounded_2: 0.4166666666666667rem;\n$_borderRadius_rounded_3: 0.6666666666666666rem;\n$_borderRadius_rounded_4: 0.8527131875356039rem;\n$_borderRadius_rounded_5: 2rem;\n$_borderRadius_rounded_6: 2.6666666666666665rem;\n$_borderRadius_rounded_7: 3.5rem;\n$_borderRadius_rounded_8: 4.5rem;\n$_borderRadius_rounded_9: 83.25rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `Layout_layout__+nPRX`,
	"main": `Layout_main__FV28D`
};
export default ___CSS_LOADER_EXPORT___;
