// ProtectedRoute.js
import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";

const ProtectedRoute:React.FC<{children : React.ReactNode}> = ({ children }) => {
  const location = useLocation();

  // Kiểm tra `state` từ location
  return location.state?.ws ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
};

export default ProtectedRoute;
