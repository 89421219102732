import React, { useState, useEffect } from 'react';
import { GameState, GameWebSocket } from './types/game';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Game from './pages/Game';
import Home from './pages/Home';
import HowToPlayPage from './pages/HowToPlay';
import LeaderBoard from './pages/LeaderBoard';
import Profile from './pages/Profile';
import WaitingRoomPage from './pages/WaitingRoom';
import { GameProvider } from './contexts/GameContext';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProtectedRoute from './components/ProtectedRoute';
import CheckWallet from './components/CheckWallet';
import ErrorPage from './pages/ErrorPage';

const App: React.FC = () => {
  const [ws, setWs] = useState<GameWebSocket | null>(null);
  const [gameState, setGameState] = useState<GameState>({
    players: [],
    tokens: [],
    isStarted: false,
  });
  const [wsId, setWsId] = useState<string | null>(null);

  useEffect(() => {
    const websocket = new WebSocket(
      process.env.REACT_APP_WS_URL || 'ws://localhost:80',
    ) as GameWebSocket;

    websocket.onopen = () => {
      console.log('WebSocket Connected');
      setWs(websocket);
    };

    websocket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        console.log('App received message:', message);

        if (message.type === 'game_started') {
          setGameState({
            players: message.players,
            tokens: message.ethTokens,
            isStarted: true,
          });
        } else if (message.type === 'player_update') {
          setGameState((prev) => ({
            ...prev,
            players: message.players,
            tokens: message.ethTokens,
          }));
        } else if (message.type === 'eth_update') {
          setGameState((prev) => ({
            ...prev,
            tokens: message.ethTokens,
          }));
        } else if (message.type === 'player_id') {
          setWsId(message.wsId);
        }
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    // Cleanup on unmount
    return () => {
      websocket.close();
    };
  }, []);
  if (!ws) {
    return <div>Connecting to server...</div>;
  }

  return (
    <GameProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home ws={ws} setWs={setWs} />} />
          <Route path="/how-to-play" element={<HowToPlayPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route element={<CheckWallet />}>
            <Route
              path="/game"
              element={
                <ProtectedRoute>
                  <Game ws={ws} gameState={gameState} wsId={wsId} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/waiting-room"
              element={
                <ProtectedRoute>
                  <WaitingRoomPage ws={ws} gameState={gameState} wsId={wsId} />
                </ProtectedRoute>
              }
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/leaderboard" element={<LeaderBoard />} />
          </Route>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </GameProvider>
  );
};

export default App;
