import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import imageResources from '../../assets/image';
import './waiting_room.scss'
import { GameProps } from '../../types/game';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { formatAddress } from '../../utils/formatAddress';
import { setUserAction } from '../../store/gameSlice';
import LinkConfirm from '../../components/LinkConfirm';
import WinModalV2 from '../../components/WinModal/WinModalV2';

interface ISnake {
    index: number;
    walletId: string;
    walletAddress: string;
    isOwner: boolean;
    color: string;
}

const WaitingRoomPage: React.FC<GameProps> = ({ ws, gameState, wsId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [players, setPlayers] = useState<ISnake[]>([]);
    const [currentPlayer, setCurrentPlayer] = useState<string>('');
    const [timeLeft, setTimeLeft] = useState<string>('0:45');
    const [playerCount, setPlayerCount] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
    const [countdown, setCountdown] = useState<number | null>(null);
    const wallet = useSelector((state: RootState) => state.game.wallet);
    const dispatch = useDispatch();
    const firstRender = React.useRef(true);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<any>(null);
    const [allowKeepPlaying, setAllowKeepPlaying] = useState<boolean>(true);
    const [isPressKeepPlaying, setIsPressKeepPlaying] = useState<boolean>(false);

    useEffect(() => {
        if (countdown !== null) {
            const minutes = Math.floor(countdown / 60);
            const seconds = countdown % 60;
            setTimeLeft(`${minutes}:${seconds.toString().padStart(2, '0')}`);
        }
    }, [countdown]);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            try {
                const message = JSON.parse(event.data);
                console.log('Waiting room received message:', message);

                switch (message.type) {
                    case "waiting_room_update": {
                        const updatedPlayers = message.payload.players.map((player: any, index: number) => ({
                            index,
                            walletId: player.username,
                            walletAddress: player.walletAddress,
                            isOwner: player.walletAddress === wallet.address,
                            color: player.color
                        }));
                        
                        setPlayers(updatedPlayers);
                        setPlayerCount(message.payload.players.length);

                        if (message.payload.timeLeft !== undefined) {
                            setCountdown(message.payload.timeLeft);
                        }

                        if (message.payload.isNewRoom) {
                            setCountdown(message.payload.timeLeft);
                        }
                        break;
                    }

                    case "countdown_started": {
                      console.log('countdown_started', message.timeLeft);
                        setCountdown(message.timeLeft);
                        break;
                    }

                    case "countdown_update": {
                        console.log('countdown_update', message);
                        setCountdown(message.timeLeft);
                        const updatedPlayers = message.players.map((player: any, index: number) => ({
                          index,
                          walletId: player.username,
                          walletAddress: player.walletAddress,
                          isOwner: player.walletAddress === wallet.address,
                          color: player.color
                        }));
                        setPlayers(updatedPlayers);
                        setPlayerCount(message.players.length);
                        break;
                    }

                    case "game_started": {
                        navigate('/game', { state: { ws: wallet } });
                        break;
                    }

                    case "waiting_room_timeout": {
                        setError("Game cancelled due to insufficient players");
                        setTimeout(() => {
                          window.location.href = '/'
                        }, 3000);
                        break;
                    }
                    
                    case "game_ended": {
                      
                      console.log(
                        'Game ended:',
                        message,
                        '____',
                        wsId,
                        '___',
                        message?.player?.id,
                      );
                      if (message?.winData) {
                        setAllowKeepPlaying(false);
                        setTimeout(() => {
                          window.location.href = '/';
                        }, 10000);
                      }
                      if (wsId == message?.player?.id) {
                        if (message?.result === 'win') {
                          setShowModal(true);
                          setModalContent({
                            type: 'win',
                            message: message.message,
                            winData: message.winData,
                            winner: message.winner,
                            player: message.player,
                          });
                        } else {
                          setShowModal(true);
                          setModalContent({
                            type: 'lose',
                            message: 'You lost the game!',
                            player: message.player,
                          });
                        }
                        // Gửi message ready cho game mới thay vì đóng ws
                        ws.send(JSON.stringify({
                          type: "ready_for_new_game",
                          walletAddress: message.player.walletAddress
                        }));
                      }
                      break;
                    }
                    
                    case "redirect_home": {
                        setTimeout(() => {
                            dispatch(setUserAction({
                                confirmExit: false,
                            }))
                            ws.close();
                            window.location.href = '/';
                        }, 3000);
                        break;
                    }
                    
                    case "error": {
                      setError(message?.message);
                      setTimeout(() => {
                        window.location.href = '/';
                      }, 3000);
                    }
                }
            } catch (error) {
                console.error('Error handling message:', error);
                setError('An error occurred while processing the message');
            }
        };

        const username = wallet.playerName || formatAddress(wallet.address || '');
        setCurrentPlayer(username);
        
        ws.addEventListener('message', handleMessage);
        return () => ws.removeEventListener('message', handleMessage);
    }, [ws, navigate, wallet]);

    const renderPlayerSlots = () => {
        const slots = Array(10).fill(null).map((_, index) => {
            const player = players.find(p => p.index === index);

            return (
                <div key={index} className={`flex__child`}>
                    <div className={'relative'}>
                        <img src={player?.isOwner ? imageResources.cardPlayOwner : imageResources.cardPlay} alt="" />
                        <div className={'absolute flex flex-col w-full h-full top-0 left-0 justify-between items-center py-2'}>
                            {player ? (

                                <>
                                    <p className={'jersey-15-regular text-Text-03 text-[4vw] sm:text-[20px] md:text-[32px] text-center'}>
                                        {player.isOwner ? 'YOU' : <span>&nbsp;</span>}
                                    </p>
                                    <img
                                        className={'w-[70%]'}
                                        src={imageResources[  `snake${player.color}` as keyof typeof imageResources]}
                                        alt=""
                                    />
                                    <p className={'jersey-15-regular text-[4vw] sm:text-[20px] md:text-[32px] text-Text-01 text-center w-[80%] truncate'}>
                                        {player.walletId}
                                    </p>
                                </>
                            ) : (
                                // Nếu chưa có player thì hiển thị Waiting...
                                <div className={'flex flex-col w-full h-full justify-center items-center'}>
                                    <p className={'jersey-15-regular text-Text-01 text-center'}>
                                        Waiting...
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        });

        return slots;
    };
  
  const GameModal = useMemo(() => {
    if (!showModal || !modalContent || isPressKeepPlaying) return null;
    
    return (
      <WinModalV2
        winner={modalContent.winner}
        windata={modalContent?.winData}
        player={modalContent.player}
        onPlayAgain={() => {
          setShowModal(false);
          setModalContent(null);
        }}
        isWin={modalContent.type === 'win'}
        closeModal={setShowModal}
        allowKeepPlaying={allowKeepPlaying}
        pressedKeepPlaying={setIsPressKeepPlaying}
      />
    );
  }, [showModal, modalContent, allowKeepPlaying, isPressKeepPlaying]);

    return (
      <Layout ws={ws}>
        <div className={'container mx-auto'}>
          {error && (
            <div className="text-red-500 text-center p-4 bg-red-100 rounded">
              {error}
            </div>
          )}
          {GameModal}
          <div className={'content'}>
            <div className={'blackArea !pt-[5vh]'}>
              <div
                className={
                  'container overflow-hidden mx-5 sm:mx-10 md:mx-auto pt-2 border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
                }
              >
                <div
                  className={
                    'flex justify-between pb-2 border-Stroke-01 border-b-[8px] px-[23px]'
                  }
                >
                  <LinkConfirm className={'w-1/3 flex items-center'} willConfirm={ws?.readyState === WebSocket.OPEN}>
                      <img
                        className={'w-[20px] sm:w-auto'}
                        src={imageResources.arrow}
                        alt=""
                      />
                  </LinkConfirm>
                  <p
                    className={
                      'jersey-15-regular text-2xl sm:text-[28px] md:text-[32px] text-Text-03 w-1/3 text-center'
                    }
                  >
                    PLAYER: {playerCount}/10
                  </p>
                  <p
                    className={
                      'jersey-15-regular text-2xl sm:text-[24px] md:text-[32px] text-Text-01 w-1/3 flex items-center justify-end gap-3'
                    }
                  >
                    {timeLeft}
                    <img
                      className={'w-[20px] sm:w-auto'}
                      src={imageResources.watch}
                      alt=""
                    />
                  </p>
                </div>

                <div
                  className={
                    'bg-BG-02 p-[4vw] sm:p-[20px] md:p-[35px_20px_50px] max-w-[1200px]:p-[35px]'
                  }
                >
                  <div className={'flex__parent'}>{renderPlayerSlots()}</div>
                </div>
              </div>
            </div>
            <p className={'mx-auto body-2-semibold text-Text-01 text-center mb-4'}>
              If you leave or lag out of the lobby
              <br /> you will lose your entry fee
            </p>
          </div>
        </div>
      </Layout>
    );
};

export default WaitingRoomPage;
