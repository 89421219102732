import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameState as WebSocketGameState } from './../types/websocket';
import { WalletState } from '../types/wallet';
import { ReduxGameState } from '../types/store';

// interface ReduxGameState {
//   gameState: WebSocketGameState;
//   currentPlayer: string | null;
//   highScore: number;
//   isLoading: boolean;
//   error: string | null;
//   winner: {
//     name: string;
//     score: number;
//   } | null;
//   wallet: WalletState;
//   settings: {
//     speed: number;
//     gridSize: number;
//     foodCount: number;
//     entryFee: string;
//     soundEnabled: boolean;
//     difficulty: string;
//   };
//   userAction: {
//     confirmExit: boolean;
//   };
// }

const initialState: ReduxGameState = {
  gameState: {
    isConnected: false,
    players: {},
    food: { x: 15, y: 15 },
    isStarted: false,
    isPaused: false,
    isFinished: false,
    dimension: [800, 600],
    ethTokens: [],
    winner: null,
  },
  currentPlayer: null,
  highScore: 0,
  isLoading: false,
  error: null,
  winner: null,
  wallet: {
    address: null,
    balance: '0',
    isConnecting: false,
    isConnected: false,
    network: null,
    playerName: null,
  },
  settings: {
    speed: 0.5,
    gridSize: 20,
    foodCount: 3,
    entryFee: '0.001',
    soundEnabled: true,
    difficulty: 'medium',
  },
  userAction: {
    confirmExit: false,
  },
  auth: {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo') as string)
      : null,
    accessToken: localStorage.getItem('accessToken') || '',
  },
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameState: (state, action: PayloadAction<WebSocketGameState>) => {
      state.gameState = action.payload;
    },
    updateGameState: (
      state,
      action: PayloadAction<Partial<WebSocketGameState>>,
    ) => {
      state.gameState = { ...state.gameState, ...action.payload };
    },
    setWinner: (
      state,
      action: PayloadAction<{ name: string; score: number } | null>,
    ) => {
      state.winner = action.payload;
    },
    setWalletState: (state, action: PayloadAction<Partial<WalletState>>) => {
      state.wallet = { ...state.wallet, ...action.payload };
    },
    setUserAction: (
      state,
      action: PayloadAction<Partial<ReduxGameState['userAction']>>,
    ) => {
      state.userAction = { ...state.userAction, ...action.payload };
    },
    setAuth: (
      state,
      action: PayloadAction<Partial<ReduxGameState['auth']>>,
    ) => {
      state.auth = { ...state.auth, ...action.payload };
    },
  },
});

export const {
  setGameState,
  updateGameState,
  setWinner,
  setWalletState,
  setUserAction,
  setAuth,
} = gameSlice.actions;
export default gameSlice.reducer;
