import React from 'react';
import Layout from '../../components/Layout';
import imageResources from '../../assets/image';

const HowToPlayPage: React.FC = () => {
  return (
    <Layout>
      <div className={'container mx-auto'}>
        <div className={'content'}>
          <div className={'blackArea !pt-[5vh]'}>
            <div
              className={
                'container overflow-hidden mx-5 sm:mx-10 md:mx-auto pt-2 border-Stroke-01 border-[8px] rounded-[24px] bg-BG-02 max-w-[1061px] mt-5'
              }
            >
              <div
                className={
                  'flex justify-between pb-2 border-Stroke-01 border-b-[8px] px-[23px]'
                }
              >
                <p
                  className={
                    'jersey-15-regular text-2xl sm:text-[32px] text-Text-03'
                  }
                >
                  How 2 Play
                </p>
              </div>

              <div className={'bg-BG-01'}>
                <div className="flex flex-col md:flex-row justify-center items-center md:items-start space-y-8 md:space-y-0 md:space-x-6 pt-[60px] pb-[120px] px-[24px]">
                  {/* Step 1 */}
                  <div className="flex flex-col items-center text-white">
                    <h2 className="jersey-15-regular text-[24px] text-Text-01 mb-2">
                      STEP 1
                    </h2>
                    <img
                      src={imageResources.step1}
                      alt="Step 1"
                      className="mb-4"
                    />

                    <p className="text-Text-03 body-2-semibold text-center px-4">
                      Using your D-pad or WASD to control your snake
                    </p>
                  </div>

                  {/* Step 2 */}
                  <div className="flex flex-col items-center text-white">
                    <h2 className="jersey-15-regular text-[24px] text-Text-01 mb-2">
                      STEP 2
                    </h2>
                    <img
                      src={imageResources.step2}
                      alt="Step 2"
                      className="mb-4"
                    />

                    <p className="text-Text-03 body-2-semibold text-center px-4">
                      Collect ETH tokens to grow the snake in size
                    </p>
                  </div>

                  {/* Step 3 */}
                  <div className="flex flex-col items-center text-white">
                    <h2 className="jersey-15-regular text-[24px] text-Text-01 mb-2">
                      STEP 3
                    </h2>
                    <img
                      src={imageResources.step3}
                      alt="Step 3"
                      className="mb-4"
                    />

                    <p className="text-Text-03 body-2-semibold text-center px-4">
                      Avoid other players to be the last one alive
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HowToPlayPage;
