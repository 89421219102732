// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex__parent {
  --flex-items: 5;
  --flex-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--flex-gap);
}
@media screen and (max-width: 1200px) {
  .flex__parent {
    --flex-gap: 10px;
    --flex-items: 4;
  }
}
@media screen and (max-width: 768px) {
  .flex__parent {
    --flex-gap: 10px;
    --flex-items: 4;
  }
}
@media screen and (max-width: 540px) {
  .flex__parent {
    --flex-gap: 10px;
    --flex-items: 2;
  }
}
.flex__parent .flex__child {
  flex-basis: calc(100% / var(--flex-items) - (var(--flex-items) - 1) / var(--flex-items) * var(--flex-gap));
}`, "",{"version":3,"sources":["webpack://./src/pages/WaitingRoom/waiting_room.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EAaA,aAAA;EACA,eAAA;EACA,oBAAA;AAXF;AAHE;EAHF;IAII,gBAAA;IACA,eAAA;EAMF;AACF;AALE;EAPF;IAQI,gBAAA;IACA,eAAA;EAQF;AACF;AAPE;EAXF;IAYI,gBAAA;IACA,eAAA;EAUF;AACF;AANE;EACE,0GAAA;AAQJ","sourcesContent":[".flex__parent {\n  --flex-items: 5;\n  --flex-gap: 20px;\n  @media screen and (max-width: 1200px) {\n    --flex-gap: 10px;\n    --flex-items: 4;\n  }\n  @media screen and (max-width: 768px) {\n    --flex-gap: 10px;\n    --flex-items: 4;\n  }\n  @media screen and (max-width: 540px) {\n    --flex-gap: 10px;\n    --flex-items: 2;\n  }\n  display: flex;\n  flex-wrap: wrap;\n  gap: var(--flex-gap);\n  .flex__child {\n    flex-basis: calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
