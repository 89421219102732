import join_1 from './_join_1.svg';
import join_2 from './_join_2.svg';
import logo from './_logo.svg';
import logo2 from './_logo_2.svg';
import connect from './_connect.svg';
import connected from './_connected.svg';
import game from './_game.svg';
import info from './_info.svg';
import profile from './_profile.svg';
import ranking from './_ranking.svg';
import checked from './_checked.svg';
import unchecked from './_uncheck.svg';
import ruby from './_ruby.svg';
import topHowToPlay from './_top_how_to_play.png';
import frameButtonLg from './_frame_button_lg.svg';
import frameButtonMDInactive from './_frame_button_md_inactive.svg';
import frameButtonClaim from './_frame_button_claim.svg';
import user from './_user.svg';
import singleRuby from './_single_ruby.svg';
import rank1 from './_rank_1.svg';
import rank2 from './_rank_2.svg';
import rank3 from './_rank_3.svg';
import step1 from './_step1.png';
import step2 from './_step2.png';
import step3 from './_step3.png';
import snake1 from './_snake_1.svg';
import snake2 from './_snake_2.svg';
import snake3 from './_snake_3.svg';
import snake4 from './_snake_4.svg';
import snake5 from './_snake_5.svg';
import snake6 from './_snake_6.svg';
import snake7 from './_snake_7.svg';
import snake8 from './_snake_8.svg';
import snake9 from './_snake_9.svg';
import snake10 from './_snake_10.svg';
import cardPlay from './_card_play.png';
import cardPlayOwner from './_card_play_owner.png';
import arrow from './_arrow.svg';
import watch from './_watch.svg';
import backToHome from './_back_to_home.svg';
import keepWatching from './_keep_watching.svg';
import isMobile from './_is_mobile.svg';
import countDown from './_count_down.svg';



const imageResources = {
  join1: join_1,
  join2: join_2,
  logo: logo,
  logo2: logo2,
  connect: connect,
  connected: connected,
  game: game,
  info: info,
  user,
  profile: profile,
  ranking: ranking,
  checked: checked,
  unchecked: unchecked,
  ruby: ruby,
  singleRuby,
  topHowToPlay: topHowToPlay,
  frameButtonLg: frameButtonLg,
  frameButtonMDInactive: frameButtonMDInactive,
  frameButtonClaim: frameButtonClaim,
  rank1,
  rank2,
  rank3,
  step1,
  step2,
  step3,
  snake1,
  snake2,
  snake3,
  snake4,
  snake5,
  snake6,
  snake7,
  snake8,
  snake9,
  snake10,
  cardPlay,
  cardPlayOwner,
  arrow,
  watch,
  backToHome,
  keepWatching,
  isMobile,
  countDown
};

export default imageResources;