import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface Player {
    id: string;
    username: string;
    score: number;
    color: string;
    isDead?: boolean;
}

interface GameState {
    players: Player[];
    setPlayers: (players: Player[]) => void;
}

const GameContext = createContext<GameState | undefined>(undefined);

export const GameProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [players, setPlayers] = useState<Player[]>([]);
    const [ws, setWs] = useState<WebSocket | null>(null);

    useEffect(() => {
        const websocket = new WebSocket(process.env.REACT_APP_WS_URL || 'ws://localhost:80');

        websocket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            switch (data.type) {
                case 'waiting_room_update':
                    setPlayers(data.payload.players);
                    break;
                case 'player_update':
                    setPlayers(data.players);
                    break;
                case 'game_started':
                    setPlayers(data.players);
                    break;
            }
        };

        setWs(websocket);

        return () => {
            websocket.close();
        };
    }, []);

    return (
        <GameContext.Provider value={{ players, setPlayers }}>
            {children}
        </GameContext.Provider>
    );
};

export const useGameState = () => {
    const context = useContext(GameContext);
    if (context === undefined) {
        throw new Error('useGameState must be used within a GameProvider');
    }
    return context;
}; 